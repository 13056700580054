// Core
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Third-Party Libraries
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { IoIosClose } from "react-icons/io";

// Components
import GridContainer from 'components/molecules/GridContainer';
import MyClassRoom from 'components/molecules/MyClassRoom';
import UserCard from 'components/atoms/UserCard';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import CreatePostModal from 'components/atoms/CreatePostModal';
import PostFooter from 'components/atoms/PostFooter';
import PostImages from 'components/atoms/PostImages';
import PostDoc from 'components/atoms/PostDoc';
import PostUrls from 'components/atoms/PostUrls';
import ModalView from 'components/atoms/ModalView';
import PrimaryButton, { SecondryButton } from 'components/atoms/CustomButtons';
import CreatePostEditModal from 'components/atoms/CreatePostEditModal';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import UrlContentTextWrapper from 'components/atoms/UrlContentTextWrapper';
import PollCard from 'components/molecules/PollCard';
import DeleteModal from 'components/atoms/DeleteModal';

// Styles and Constants
import './index.scss';
import { SingleFeed, WriteOnBoard } from './styles';
import { colors } from 'assets/styles/colors';
import Images from 'assets/image';

// Utilities
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { checkType, formatDate, getTypeString, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { useCheckRole, useCustomSWR, useLogout } from 'utils/customHooks';
import { CommonTypes, DocumentDetailsDeleteModal, FeedData, MODAL_STYTLE, NO_POSTS, restrictModalData, SnackBarMessages, StatusType } from 'utils/constants';

const Feed: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const logout = useLogout()
    const { isInstructor } = useCheckRole()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const subjects = classroomData?.subjectList
    const [feed, setFeed] = useState<any>([]);
    const [page, setPage] = useState(1);
    const [isLoding, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [isRestricted, setRestricted] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [anchorElPost, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selected, setSelected] = useState<any>(null);
    const [selectedIndex, setSelectedIndex] = useState<any>(null)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [reportModal, setReportModal] = useState<boolean>(false)
    const [reportItem, setReportItem] = useState<any>(null)
    const [reportLoading, setReportLoading] = useState<boolean>(false)
    const [reportSuccess, setReportSuccess] = useState<boolean>(false)
    const [reportReasons, setreportReasons] = useState([])
    const [reportReasonLoading, setReportReasonLoading] = useState<boolean>(false)
    const [reportError, setReportError] = useState<string | null>(null)

    let params = {
        classRoomId: activeClassroom?._id,
        limit: 10,
        page: page,
    };

    const { data, isLoading, mutate } = useCustomSWR(restAPIs.getFeed(params), [params]);

    useEffect(() => {
        fetchReportReason()
    }, []);

    useEffect(() => {
        if(!user?.organizationId) {
            logout()
        }
    }, [user?.organizationId]);

    useEffect(() => {
        if (data) {
            setFeed((prev: any) => page === 1 ? data?.data?.posts : [...prev, ...data?.data?.posts]);
        }
    }, [data]);

    const fetchReportReason = async () => {
        setReportReasonLoading(true)
        const res = await fetchData(restAPIs.getReportReason(), navigate)
        setReportReasonLoading(false)
        if (res.statusCode === 200 && res.data) {
            setreportReasons(res.data)
        }
    }

    const loadMore = () => {
        if (!data?.data?.isLastPage && !isLoading) {
            setPage(data?.data?.nextPage ?? page);
        }
    }

    const onEditPost = () => {
        handleMenuClose()
        setEditOpen(true)
    }

    const onDeletePost = () => {
        handleMenuClose()
        setDeleteModal(true)
    }

    const onReportPost = () => {
        setReportModal(true)
        setReportSuccess(false)
        handleMenuClose()
        setReportItem(null)
        setReportError(null)
    }

    const onDelete = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDeletePost(selected?._id), navigate)
        if (res?.data && res.statusCode == 200) {
            let temp = [...feed]
            temp.splice(selectedIndex, 1);
            setFeed([...temp])
            showSnackbar(SnackBarMessages.PostDeletedSuccessfully, StatusType.success)
            setDeleteModal(false)
            mutate()
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
        setLoading(false)
    }

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>, item: any, index: any) => {
        setAnchorEl(event.currentTarget);
        setSelected(item);
        setSelectedIndex(index);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onReportSubmit = async () => {
        setReportError(null)
        if (reportSuccess) {
            setReportModal(false)
        }
        else {
            if (reportItem) {
                let params = {
                    type: reportItem?.type,
                };
                setReportLoading(true)
                const res = await fetchData(restAPIs.getReportPost(selected?._id, params), navigate)
                setReportLoading(false)
                if (res.statusCode === 200) {
                    setReportSuccess(true)
                }
                else {
                    showSnackbar(SnackBarMessages.ApologiesUnableProcessReport, StatusType.info)
                    setReportError(res?.data?.message)
                }
            }
            else {
                showSnackbar(SnackBarMessages.PleaseChooseOption, StatusType.warning)
            }
        }
    }

    const ReportModal = () => {
        return (
            <div className='delete-confirmation'>
                <div className='__header'>
                    <h4 className='class-switch-text'>
                        {!reportSuccess ? FeedData.Report : FeedData.ThanksForReporting}
                    </h4>
                    <button onClick={() => setReportModal(false)}>
                        <IoIosClose />
                    </button>
                </div>
                {!reportSuccess &&
                    <div>
                        {reportReasons?.map((item: any, index: any) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => setReportItem(item)}
                                    className={`report-item ${reportItem == item ? 'selected' : ''}`}>
                                    {item?.type}
                                </div>
                            )
                        })}
                    </div>}
                {reportReasonLoading && <div className='loading'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
                {reportSuccess &&
                    <div className='report-text'>
                        {FeedData.ReportPost}
                    </div>}
                    {reportError &&
                <div className='error-text'>* {reportError}</div>}
                <div className='button-wrapper'>
                    <PrimaryButton
                        disabled={!reportItem}
                        isLoading={reportLoading}
                        onClick={onReportSubmit}>
                        {reportSuccess ? FeedData.Ok : FeedData.Report}
                    </PrimaryButton>
                </div>
            </div>
        )
    }

    const onRefresh = () => mutate()

    const handleClose = () => {
        setEditOpen(false)
        setOpen(false)
        setSelected(null)
        setSelectedIndex(null)
    };

    const getSubjectName = (details: any) => {
        let name = '';
        let subId = details?.subjectId ?? '';
        subjects?.length > 0 &&
            subjects.map((sub: any) => {
                if (sub._id == subId) {
                    name = sub.subjectName;
                }
            });
        return name;
    };

    const handleOpen = () => setOpen(true);

    const onShow = (item: any) => {
        const type = item?.type
        if (type) {
            if (type == CommonTypes.NOTE) {
                navigate(`/notes/ref/${item.refId}`)
            }
            if (type == CommonTypes.DOCUMENT) {
                navigate(`/documents/ref/${item.refId}`)
            }
            if (type == CommonTypes.EVENT) {
                navigate(`/events`)
            }
        }
    };

    const openPostModal = () => {
        if (activeClassroom?.memberPermissions?.board || isInstructor())
            setOpen(true)
        else setRestricted(true)
    }

    const onCloseReport=()=>{
        setReportModal(false)
        setReportError(null)
    }

    return (
        <GridContainer
            loading={isLoading}
            loadMore={loadMore}
            page={page}
            pageTitle={FeedData.Home}
            leftGridPartition={3 / 4}
        >
            <MyClassRoom />
            <div className='__feed_flow' style={{ marginBottom: '20vh' }}>
                {isInstructor()?
                <WriteOnBoard onClick={openPostModal}>
                    <span>{FeedData.WriteOnBoard}</span>
                    <div className='__buttons'>
                        <i className="fi fi-rr-square-poll-vertical"></i>
                        <i className="fi fi-rr-picture"></i>
                        <i className="fi fi-rr-link"></i>
                        <i className="fi fi-rr-clip"></i>
                        <button>{FeedData.Post}</button>
                    </div>
                </WriteOnBoard>:
                feed?.length > 0 &&
                 <Typography variant="h3" className='home-feed-title' fontSize={15}>
                 Let's Catchup
             </Typography>
                }
                {feed?.map((item: any, index: number) => {
                    const { year, month, day, time } = formatDate(item?.startTime) || {}
                    const endDate = formatDate(item?.endTime)
                    return (
                        <div key={item?._id}>
                            <SingleFeed key={index}>
                                <UserCard
                                    handleClick={handleClick}
                                    getType={getTypeString(item?.type)}
                                    selectedItem={item}
                                    selectedIndex={index}
                                    item={item}
                                    actionEnabled={(item.type === CommonTypes.NOTE || item.type === CommonTypes.DOCUMENT || item.type === CommonTypes.EVENT) ? false : true}>
                                    {item == selected &&
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorElPost}
                                            keepMounted
                                            open={Boolean(anchorElPost)}
                                            onClose={handleMenuClose}

                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            className='___menu'
                                            PaperProps={{
                                                sx: {
                                                    borderRadius: '10px',
                                                    boxShadow: 'none',
                                                    border: '1px solid #eee'
                                                },
                                            }}
                                        >
                                            {item?.userId == user?._id && item?.type !== 'POLL' &&
                                                <MenuItem key={item?._id} className='item-container' onClick={onEditPost}>
                                                    {FeedData.Edit}
                                                </MenuItem>}

                                            {item?.userId == user?._id &&
                                                <MenuItem key={item?._id} className='item-container' onClick={onDeletePost}>
                                                    {FeedData.Delete}
                                                </MenuItem>}
                                            {item?.userId !== user?._id &&
                                                <MenuItem key={item?._id} className='item-container' onClick={onReportPost}>
                                                    {FeedData.Report}
                                                </MenuItem>}
                                        </Menu>}
                                </UserCard>
                                <div className='feed_container'
                                    onClick={() => onShow(item)}>
                                    {(item.type === CommonTypes.NOTE ||
                                        item.type === CommonTypes.DOCUMENT) && getSubjectName(item)?.length > 0 ? (
                                        <Tooltip title={getSubjectName(item)}>
                                            <div className='subject-tab'>{getSubjectName(item)}</div>
                                        </Tooltip>) : null}
                                    {checkType(item.type) && (
                                        <p className='feed-subject-title'>
                                            {item.title}
                                        </p>
                                    )}
                                    <UrlContentTextWrapper tagged={item.tagged ?? []} content={item?.content} />
                                    {item?.type === CommonTypes.EVENT && <div className="__event_sec">
                                        <div className="__sing_ev_sc">
                                            <span>{FeedData.StartDate}</span>
                                            <div>{month} {day} {year} - {time}</div>
                                        </div>
                                        <div className="__sing_ev_sc">
                                            <span>{FeedData.EndDate}</span>
                                            <div>{endDate?.month} {endDate?.day} {endDate?.year} - {endDate?.time}</div>
                                        </div>
                                    </div>}
                                    {item?.type === CommonTypes.POLL &&
                                        <PollCard post={item} />}
                                        <div onClick={(event) => event.stopPropagation()}>
                                    <PostImages item={item.documents} />
                                    </div>
                                    <PostDoc item={item.documents} />
                                    <PostUrls item={item.externalInfo} />
                                </div>
                                <PostFooter key={`${item._id}-${item.documents.map((doc: any) => doc._id).join('-')}`} item={{ ...item }} from='posts' />
                            </SingleFeed>
                        </div>
                    )
                })}
                {feed?.length == 0 && !isLoading && <div className='no_post'>
                    <img src={Images.Wirit} />
                    <div className='no_post_text'>{NO_POSTS}</div>
                    <SecondryButton
                        fullWidth={false}
                        onMouseDown={openPostModal}
                        text={FeedData.CreateShare} />
                </div>}
                <CreatePostModal
                    open={open}
                    onRefresh={onRefresh}
                    modalStyle={MODAL_STYTLE}
                    feed={feed}
                    setFeed={setFeed}
                    handleClose={handleClose}
                    handleOpen={handleOpen} />
                <CreatePostEditModal
                    open={editOpen}
                    selectedIndex={selectedIndex}
                    feed={feed}
                    setFeed={setFeed}
                    editItem={selected}
                    handleClose={handleClose}
                    handleOpen={handleOpen} />
                {isLoading && <div className='feed_loader'>
                    <CircularProgress size={'20px'} color="primary" />
                </div>}
            </div>
            <CommonDeleteModal
                open={deleteModal}
                isLoading={isLoding}
                description={DocumentDetailsDeleteModal.Desc}
                title={DocumentDetailsDeleteModal.DeletePost}
                handleClose={() => setDeleteModal(false)}
                handleSubmit={() => onDelete()}
            />
            <DeleteModal
                open={isRestricted}
                onSubmit={() => setRestricted(false)}
                data={restrictModalData}
                modalStyle={MODAL_STYTLE}
                handleClose={() => setRestricted(false)}
            />
            <ModalView modalStyle={MODAL_STYTLE} open={reportModal} handleClose={onCloseReport}>
                {ReportModal()}
            </ModalView>
        </GridContainer>
    );
};

export default Feed;