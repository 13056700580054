import React, { useState } from 'react';
import { IoDocumentTextOutline } from "react-icons/io5";
import { Typography } from '@mui/material';
import { FaRegFileVideo } from "react-icons/fa";
import { FaRegFileWord } from "react-icons/fa";
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdRemove } from 'react-icons/md';
import './index.scss'
import { colors } from 'assets/styles/colors';
import { getFileType } from 'utils/commonUtils';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../SnackbarProvider';
import CircularProgress from '@mui/material/CircularProgress';
interface Props {
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    editItem?: any;
    files?: any;
    setFiles: React.Dispatch<React.SetStateAction<any>>;
    imageFile?: any;
    fileInputRef: React.RefObject<HTMLInputElement>
}

const AttachFiles: React.FC<Props> = ({ modalStyle, editItem, setFiles, files, imageFile, fileInputRef }) => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileError, setFileError] = useState<any>(null);
    const [loadingIndex, setIsLoadingIndex] = useState<number | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);

            const validFileTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                "text/plain",
                "image/jpeg",
                "image/png",
                "image/webp",
                "image/gif"
            ];

            const validFiles = newFiles.filter(file => validFileTypes.includes(file.type));

            if (validFiles?.length !== newFiles?.length) {
                showSnackbar('Some files are not valid documents or images!', 'error');
            }

            const fileItems = validFiles.map(file => {
                const fileType = getFileType(file);
                return { file, type: fileType };
            });

            setFiles((prevFiles: any) => {
                const totalFilesCount = prevFiles?.length + fileItems?.length + imageFile?.length;
                if (totalFilesCount > 10) {
                    showSnackbar('Max limit of 10 files exceeded.', 'warning');
                    const allowedFilesCount = 10 - totalFilesCount;
                    return [...prevFiles, ...fileItems.slice(0, allowedFilesCount)];
                } else {
                    return [...prevFiles, ...fileItems];
                }
            });
        }
    };

    const handleDeleteFile = async (index: number) => {
        const updatedFiles = [...files];
        const temp = updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
        if (temp?.[0]?.url) {
            setIsLoadingIndex(index)
            setIsLoading(true);
            const S3DeleteRes = await fetchData(restAPIs.getS3Delete(temp?.[0]?.url),navigate)
            setIsLoadingIndex(null)
            setIsLoading(false);
            if (S3DeleteRes.status == 200 && S3DeleteRes.data) {
                showSnackbar('File deleted', 'success')
            }
        }
    };

    const RenderModal = () => (
        <div className="create-note-modal">
            <div className="file-upload-container-3">

                <div className="file-upload-section">
                    {files.length > 0 &&
                        <span>{files?.length} files</span>}
                    <label className="file-upload-button-attach">
                        Add file
                        <input
                            ref={fileInputRef}
                            type="file"
                            multiple
                            onChange={handleFileChange} />
                    </label>
                </div>

                {files?.length > 0 && (
                    <ul className="file-list">
                        {files?.map((fileItem: any, index: any) => {
                            return (
                                <li key={index}>
                                    {fileItem.type === 'Image' || getFileType(fileItem) === 'Image' ? (
                                        <div className="file-preview">
                                            <img style={{ objectFit: 'contain' }} src={URL.createObjectURL(fileItem.file)} alt="Preview" />
                                        </div>
                                    ) : fileItem.type === 'PDF' || getFileType(fileItem) === 'PDF' ? (
                                        <AiOutlineFilePdf style={iconStyle} size={26} className="file-icon" />
                                    ) : fileItem.type === 'Document' || getFileType(fileItem) === 'Document' ? (
                                        <IoDocumentTextOutline style={iconStyle} size={24} className="file-icon" />
                                    ) : fileItem.type === 'Video' || getFileType(fileItem) === 'Video' ? (
                                        <FaRegFileVideo style={iconStyle} size={24} className="file-icon" />)
                                        : <FaRegFileWord style={iconStyle} size={24} className="file-icon" />}
                                    <div className="file-details">
                                        <div className="file-name">{fileItem.file?.name ?? fileItem?.name}</div>
                                        {loadingIndex == index ? <CircularProgress size={15} sx={{ color: colors?.primary }} /> :
                                            <MdRemove style={{ cursor: 'pointer' }} color={colors.secondary} size={20} onClick={() => handleDeleteFile(index)} />}
                                    </div>
                                </li>
                            )
                        }
                        )}
                    </ul>
                )}
            </div>
            <Typography sx={{ color: 'red', marginRight: '25px' }} align='right' variant='subtitle2'>{fileError}</Typography>
        </div>
    )
    return (
        RenderModal()
    )
};

export default AttachFiles;

const iconStyle = {
    width: '25px',
    marginRight: '10px'
}
