import { createSlice } from '@reduxjs/toolkit';
import { title } from 'process';
import { act } from 'react';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';

const classroom = {
  // errorMessage: null,
  status: 0,
  notesList: [],
  subjectList: [],
  curriculum: null,
  todaysEvents: null,
  events: null,
  documentList: [],
  metadata: null,
  isAdmin: null,
  title: '',
  isLastNote: false,
  isNoteLoading: false,
  isLastDocument: false,
  isLastSubject: false,
  isSubjectLoading: false,
  isDocumentLoading: false,
  isEventLoading: false,
  isCarriculamLoading: false,
  // addedNewPost: false,
  havePostAccesss: false,
  haveAcademicAccesss: false,
  // navigationModule: null,
  // isNewClass: false,
  // classSetupStatuses: {
  //   isClassmateAdded: true,
  //   isSubjectAdded: true,
  //   isPostAdded: true,
  // },
}

const activeClassroom = loadActiveClassroomLocalStorage()

const userSlice = createSlice({
  name: 'userDetails',
  initialState: {
    SelectedClassMetadata: null,
    SelectedClassRoom: null,
    classroomData: classroom,
  },
  reducers: {
    setSelectedClassMetadata: (state, action) => {
      state.SelectedClassMetadata = action?.payload;
    },
    setSelectedClassRoom: (state, action) => {
      state.SelectedClassRoom = action?.payload;
    },
    setUpdateNote: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 1,
        notesList: action?.payload?.offset ? state?.classroomData?.notesList?.concat(action?.payload?.notes ?? []) : action?.payload?.notes ?? [],
        isLastNote: action?.payload?.isLastPage,
        title: 'Subjects',
        isNoteLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setDeleteNote: (state, action) => {
      const classroomData = localStorage.getItem('classroomData');
      const classroomDataParse = classroomData ? JSON.parse(classroomData) : null
      const noteList = classroomDataParse?.notesList
      const temp = noteList.filter((item: any) => item._id !== action?.payload)
      const temp_classroomData = {
        ...state.classroomData,
        status: 1,
        notesList: temp,
        isLastNote: action?.payload?.isLastPage,
        title: 'Subjects',
        isNoteLoading: false
      }
      state.classroomData = temp_classroomData
      localStorage.setItem('classroomData', JSON.stringify(temp_classroomData));
    },
    setLoadingNotes: (state) => {
      const classroomData = {
        ...state.classroomData,
        isNoteLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateSubject: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 2,
        subjectList: action?.payload?.offset ? state?.classroomData?.subjectList?.concat(action?.payload?.subjects) : action?.payload?.subjects,
        title: 'Curriculum',
        isLastSubject: action?.payload?.isLastPage,
        isSubjectLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setLoadingSubject: (state) => {
      const classroomData = {
        ...state.classroomData,
        isSubjectLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateCarriculam: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 3,
        curriculum: action?.payload,
        title: 'Todays Events',
        // isCarriculamLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setLoadingCarriculam: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        ...(action?.payload ? { curriculum: null } : {}),
        isCarriculamLoading: action?.payload ?? true
      }
      state.classroomData = classroomData
    },
    setUpdateTodaysEvent: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 4,
        todaysEvents: action?.payload?.event,
        title: 'Documents',
        isEventLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setLoadingEvent: (state) => {
      const classroomData = {
        ...state.classroomData,
        isEventLoading: true
      }
      state.classroomData = classroomData
    },
    setUpdateEvent: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 5,
        events: action?.payload?.events,
        title: 'Events',
        isEventLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setUpdateDocument: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 6,
        documentList: action?.payload?.offset ? state?.classroomData?.documentList?.concat(action?.payload?.documents ?? []) : action?.payload?.documents ?? [],
        title: 'Classroom',
        isLastDocument: action?.payload?.isLastPage,
        isDocumentLoading: false
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setDeleteDocument: (state, action) => {
      const classroomData = localStorage.getItem('classroomData');
      const classroomDataParse = classroomData ? JSON.parse(classroomData) : null
      const documentList = classroomDataParse?.documentList
      const temp = documentList.filter((item: any) => item._id !== action?.payload)
      const temp_classroomData = {
        ...state.classroomData,
        status: 1,
        documentList: temp,
        isLastNote: action?.payload?.isLastPage,
        title: 'Subjects',
        isNoteLoading: false
      }
      state.classroomData = temp_classroomData
      localStorage.setItem('classroomData', JSON.stringify(temp_classroomData));
    },
    setLoadingDocument: (state) => {
      const classroomData = {
        ...state.classroomData,
        isDocumentLoading: true
      }
      state.classroomData = classroomData
    },
    setActiveClassRoom: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 7,
        title: '',
      }
      state.classroomData = classroomData
      const actvclsrm = localStorage.getItem('activeClassroom');
      const activeClassroom3 = actvclsrm ? JSON.parse(actvclsrm) : null;
      // state.activeClassroom = action?.payload;
      localStorage.setItem('activeClassroom', JSON.stringify({ ...action?.payload, timeTable: activeClassroom3?.timeTable }));
    },

    setUpdateTimeTable: (state, action) => {

      const actvclsrm = localStorage.getItem('activeClassroom');
      const activeClassroom3 = actvclsrm ? JSON.parse(actvclsrm) : null;
      const classroomData = {
        ...state.classroomData,
        status: 3,
      }
      state.classroomData = classroomData
      const temp_activeClassroom = {
        ...(activeClassroom3 || {}),
        timeTable: action?.payload,
      }
      // @ts-ignore
      // state.activeClassroom = activeClassroom;
      localStorage.setItem('activeClassroom', JSON.stringify(temp_activeClassroom));
    },

    setClassroomData: (state, action) => {
      state.classroomData = action?.payload
    },
    setPostAccess: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        havePostAccesss: action?.payload
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setAcademicAccess: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        haveAcademicAccesss: action?.payload
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setUpdateUserType: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        status: 8,
        title: '',
        isAdmin: action?.payload
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    setMetaData: (state, action) => {
      const classroomData = {
        ...state.classroomData,
        metadata: action?.payload,
      }
      state.classroomData = classroomData
      localStorage.setItem('classroomData', JSON.stringify(classroomData));
    },
    clearSelectedClassMetadata: (state) => {
      state.SelectedClassMetadata = null;
    },
    clearSelectedClassRoom: (state) => {
      state.SelectedClassRoom = null;
    },
    clearClassroomData: (state) => {
      state.classroomData = classroom;
    },
    clearActiveClassroom: (state) => {
      localStorage.setItem('activeClassroom', '');
    },
    clearNote: (state) => {
      state.classroomData.notesList = []
    },
  },
});

export const {
  setSelectedClassMetadata,
  setSelectedClassRoom,
  setUpdateNote,
  setLoadingNotes,
  setUpdateSubject,
  setLoadingSubject,
  setUpdateCarriculam,
  setLoadingCarriculam,
  setUpdateTodaysEvent,
  setLoadingEvent,
  setUpdateEvent,
  setUpdateDocument,
  setLoadingDocument,
  setActiveClassRoom,
  setUpdateTimeTable,
  setClassroomData,
  setPostAccess,
  setAcademicAccess,
  setUpdateUserType,
  setMetaData,
  clearSelectedClassMetadata,
  clearSelectedClassRoom,
  clearClassroomData,
  clearActiveClassroom,
  clearNote,
  setDeleteNote,
  setDeleteDocument
} = userSlice.actions;
export default userSlice.reducer;
