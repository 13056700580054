import { logEvent,getAnalytics,setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBnm85BLXXJIB78Iww8KMUSbc0U1p3ec7c",
  authDomain: "classteam-stg-org.firebaseapp.com",
  projectId: "classteam-stg-org",
  storageBucket: "classteam-stg-org.appspot.com",
  messagingSenderId: "110170508000",
  appId: "1:110170508000:web:eb50a808af6909616e1e85",
  measurementId: "G-Z7XE3RDQV2"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  export const logEventWeb = async (eventName: string, params: Record<string, any>) => {
    try {
      logEvent(analytics, eventName, params);
       console.log(`Event logged: ${eventName}`, params);
    } catch (error) {
      console.error("Error logging event:", error);
    }
  };
  
  export const setUserPropertiesWeb = (params: Record<string, string>) => {
    try {
      setUserProperties(analytics, params);
      // console.log("User properties set:", params);
    } catch (error) {
      console.error("Error setting user properties:", error);
    }
  };