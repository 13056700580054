import { colors } from 'assets/styles/colors';
import React, { useState } from 'react';
import { MdOutlineFileDownload, MdVisibility } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { logEventWeb } from 'utils/firebase';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';

var FileSaver = require('file-saver');


interface Note {
    url: string;
    signedUrl?: string;
    _id: string;
}

interface DownloadFileProps {
    note: Note;
    filename: string; 
    type?: string
}

const style = { minWidth: '40px', fontSize:'20px', marginLeft:'10px' }

const DownloadFileComponent: React.FC<DownloadFileProps> = ({ note, filename,type }) => {


    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [fileStatus, setFileStatus] = useState<number>(0); // 0: not started, 1: in progress, 2: completed

    const downloadFileProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
        }
    };

    const downloadFileBegin = () => {
        console.log('');
    };

    const downloadFile = async () => {
        const uri = note.url;
        const res = note.url ? note.url : note.url;
        logEventWeb('user_interaction', {
            interaction_type:type==='Notes'?'note_download': 'doc_download',
            post_id: String(note._id),
            org_id: String(user?.organizationId),
            class_id: String(activeClassroom?._id),
            custom_user_id: String(user?._id),
          });

        if (fileStatus !== 1) {
            setFileStatus(1);
            downloadFileBegin();
            try {
                FileSaver.saveAs(res, filename)
                const total = 0;
                let loaded = 0;
                const chunkSize = total / 100; 
                const interval = setInterval(() => {
                    loaded += chunkSize;
                    downloadFileProgress({
                        lengthComputable: true,
                        loaded: Math.min(loaded, total),
                        total,
                    } as ProgressEvent);
                    if (loaded >= total) {
                        clearInterval(interval);
                        setFileStatus(2);
                    }
                }, 100);
            } catch (error) {
                setFileStatus(0);
            }
        }
    };

    return (
        <div>
            <i className="fi fi-rr-eye" onClick={downloadFile} style={style} color={colors?.normal} ></i>
        </div>
    );
};

export default DownloadFileComponent;
