// Core
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import CurriculumFiles from 'components/atoms/CurriculumFiles';
import NoNotes from 'components/atoms/NoNotes';
import { CreateButton } from 'components/atoms/CustomButtons';
import CreateCurriculamModal from 'components/atoms/CreateCurriculamModal';
import CreateSubjectModal from 'components/atoms/CreateSubjectModal';

// Third-Party Libraries
import { CircularProgress, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import _ from 'lodash';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Utilities
import { filterIndexBySubjectName } from 'utils/commonUtils';
import { CurriculamTempModule } from 'utils/types';
import { CurriculamData, MODAL_STYTLE } from 'utils/constants';
import { useFetchClassRoomItems } from 'utils/customHooks';
import { useMetadataContext } from 'contextApi/MetadataContext';


const Curriculum: React.FC = () => {

    const { subjectId } = useParams()
    const { fetchCurriculum } = useFetchClassRoomItems()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const { isCarriculamLoading } = classroomData;
    const { isAdmin } = classroomData
    const [moduleData, setModuleData] = useState<any>(subjectId ? [] : classroomData.curriculum ?? []);
    const [selected, setSelected] = useState<number | null>(0)
    const [value, setValue] = React.useState(subjectId ? null : 0);
    const [createType, setCreateType] = useState<any>('');
    const [editItem, setEditItem] = useState<any>(null);
    const subjectList = classroomData?.subjectList?.length > 0 ? classroomData?.subjectList : [];
    const [openSubjectModal, setOpenSubjectModal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (value !== null)
            if (subjectList?.[value]?._id) {
                setModuleData([])
                fetchCurriculum(subjectList?.[value]?._id)
            }
    }, [value]);

    useEffect(() => {
        if (subjectId) {
            setIsLoading(true)
            const index = _.findIndex(subjectList, (item: any) => item._id === subjectId);
            handleChange(null, index)
        }
    }, [subjectId]);

    useEffect(() => {
        if(isCarriculamLoading){
            setModuleData([]);
        }
        else
        setModuleData(classroomData.curriculum ?? []);
    }, [classroomData.curriculum,isCarriculamLoading]);

    useEffect(() => {
        setIsLoading(isCarriculamLoading)
    }, [isCarriculamLoading]);

    const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
        setValue(newValue);
        onSelect(subjectList?.[newValue]?._id)
        setSelected(null)
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const selectItem = (index: number) => {
        setSelected(index)
        setSelected(index === selected ? null : index)
    }

    // useEffect(() => {
    //     onSelect(subjectList?.[value]?._id)
    // }, [value]);

    // useEffect(() => {
    //     filterIndexBySubjectName(subjectList, subject, setValue)
    //     onSelect(subjectList?.[0]?._id)
    // }, []);

    function onSelect(id: any) {
        let temp_module: CurriculamTempModule = {} as CurriculamTempModule
        if (moduleData?.length > 0) {
            moduleData?.map((item: any) => {
                if (item.subjectId == id) {
                    temp_module = item;
                }
            });
        }
        setModuleData(temp_module ?? []);
    }

    const onAddSessions = () => {
        if (subjectList?.length > 0) {
            setCreateType(CurriculamData.Session)
            setEditItem(null)
        }
        else {
            setOpenSubjectModal(CurriculamData.Subject)
        }
    }

    const onEdit = () => {
        setCreateType(CurriculamData.Session)
        if (selected !== null)
            setEditItem(moduleData?.sections?.[selected])
    }

    const onClose = () => {
        setCreateType('')
        setEditItem(null)
    }


    return (
        <GridContainer
            pageTitle={CurriculamData.Curriculum}
            leftGridPartition={1}>
            <Tabs
                sx={{ '& .MuiTabs-indicator': { backgroundColor: colors?.primary } }}
                value={value}
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="basic tabs example">
                {subjectList?.map((label: any, index: number) => (
                    <Tab wrapped label={label?.subjectName} {...a11yProps(index)} />
                ))}
            </Tabs>
            {(!isLoading&&moduleData&&moduleData?.length == 0 && !moduleData?._id) ?
                <NoNotes 
                onClick={onAddSessions} 
                subjectList={subjectList?.length == 0} 
                isOwned={isAdmin} 
                type={CurriculamData.Curriculum} /> :
                <div className='curriculum-wrapper'>
                    {moduleData?.sections?.map((item: any, index: number) => (
                        <div>
                            <div onClick={() => selectItem(index)} className={`title ${selected == index ? 'selected' : ''}`}>
                                <Typography variant='body1'>{item?.title}</Typography>
                                {selected == index ?
                                    <KeyboardArrowUpIcon className={`icon ${selected == index ? 'selected' : ''}`} /> :
                                    <KeyboardArrowDownIcon className={`icon ${selected == index ? 'selected' : ''}`} />}
                            </div>
                            {selected !== null &&
                                <div className={`${selected == index ? 'dropdown-menus' : 'hide-dropdown-menu'}`} >
                                    <CurriculumFiles data={moduleData?.sections?.[selected]} moduleData={moduleData} selected={selected} onEdit={onEdit} isOwned={isAdmin} />
                                </div>}
                        </div>
                    ))}
                </div>
            }
            {isLoading &&
                <div className='loader-container'>
                    <CircularProgress size={15} sx={{ color: colors?.primary }} />
                </div>}
            <CreateCurriculamModal
                open={createType == CurriculamData.Session}
                editItem={editItem}
                selected={selected}
                subject={subjectList?.[value ?? 0]}
                module={moduleData}
                createType={createType}
                modalStyle={MODAL_STYTLE}
                handleClose={onClose} />
            <CreateSubjectModal
                disableReroute={true}
                open={openSubjectModal == CurriculamData.Subject}
                modalStyle={MODAL_STYTLE}
                createType={openSubjectModal}
                handleClose={() => setOpenSubjectModal('')} />
        </GridContainer>
    )
};

export default Curriculum;
