import React, { useRef, useState } from 'react';
import './index.scss'
import ModalView from '../ModalView';
import PrimaryButton from '../CustomButtons';
import { IoIosClose } from "react-icons/io";
import { FEEDS, POST_PATH } from "utils/restAPIs"
import { useSnackbar } from '../SnackbarProvider';
import { WriteOnBoard2 } from '../../../pages/Feed/styles';
import PhotoPicker from '../PhotoPicker';
import URLInput from '../URLInput';
import AttachFiles from '../AttachFiles';
import { Badge } from '@mui/material';
import axios from 'axios';
import PostPhotoScroller from '../PostPhotoScroller';
import { loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import MentionPostTextArea from '../MentionPostTextArea';
import CreatePoll from '../CreatePoll';
import _ from 'lodash';
import PollPreview from '../PollPreview';
import { CommonTypes, CreatePostModalData, CursorType } from 'utils/constants';
import { CreatePostModalProps, FileItem, SubjectChange } from 'utils/types';
import { readFileAsBinary, uploadFile } from 'utils/filesupload';
import { logEventWeb } from 'utils/firebase';


const CreatePostModal: React.FC<CreatePostModalProps> = ({ open, handleClose, modalStyle, feed, setFeed, onRefresh }) => {


  const { showSnackbar } = useSnackbar();
  const [post, setPost] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const activeClassroom = loadActiveClassroomLocalStorage()
  const userObject = loadUserFromLocalStorage();
  const user = userObject?.userDetails
  const [imageFile, setImageFile] = useState<any>([]);
  const [percentage, setPercentage] = useState(0);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [files, setFiles] = useState<FileItem[]>([])
  const [urls, setUrls] = useState<string[]>([]);
  const [options, setPollOptions] = useState<any>([]);
  const [pollTitle, setPollTitle] = useState('');
  const fileAttachmentRef = useRef<HTMLInputElement>(null);

  const [mentionedUsers, setMentionedUsers] = useState<any>([]);
  const [createPoll, setCreatePoll] = useState<boolean>(false);

  const path = `${POST_PATH}`
    .replace('{orgId}', user?.organizationId)
    .replace('{classRoomId}', activeClassroom._id);

  const handlePostChange = (event: SubjectChange) => {
    const display = event.target.value;
    setPost(display);
    setError('')
  }

  const onSubmit = async () => {
    const filesArray = files.map(obj => obj.file);
    const uploadedData = [...imageFile, ...filesArray]
    let tempFiles = [];
    setIsLoading(true);
    if (uploadedData?.length > 0) {
      const time = new Date();
      const filesArray = Array.from(uploadedData);
      for (let index = 0; index < filesArray.length; index++) {
        const f = filesArray[index];
        setPercentage(index / filesArray.length * 100)
        if (tempFiles.length + files?.length < 10) {
         
          const nameParts = f.name?.split('.');
          const extension = nameParts ? `.${nameParts[nameParts.length - 1]}` : '';
          const uniqueFilename = `${Math.random().toString(36).substring(7)}${time.getTime().toString()}${extension}`.replace(/[%\s]/g, '');
          const fileType = f.type;
          const filePath = `${path}${uniqueFilename}`;
          const binaryBlob = await readFileAsBinary(f);
          const downloadUrl = await uploadFile({ filePath, fileType, file: binaryBlob });
          const postImageItem = {
            url: downloadUrl,
            uniqueFileName: uniqueFilename,
            type: fileType,
            size: f.size,
            name: f.name,
          };
          tempFiles.push(postImageItem);
        } else {
          showSnackbar('Max limit of 10 files exceeded.', 'warning')
        }
      };
    }
    const config = {
      headers: {
        Authorization: localStorage.getItem('sessiontoken'),
      },
      onUploadProgress: (progressEvent: any) => {
        setPercentage(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
      },
    };
    const taggedUserIds = mentionedUsers.map((usr: { _id: string }) => usr._id);
    const cleanedDisplay = post.replace(/@\[(.*?)\]\(.*?\)/g, '$1'); // Removes @, [, ], (, and ) from the display
    let params: any = {};
    tempFiles.forEach(file => {
      params.documents = {
        url: file.url,
        type: file.type,
        name: file.name,
        size: file.size,
        uniqueFileName: file.uniqueFileName,
      };
    });
    params.classRoomId = activeClassroom?._id;
    params.content = cleanedDisplay;
    params.tagged = taggedUserIds;
    params.documents = tempFiles || [];
    params.externalInfo = urls || [];
    if (options?.length > 0) {
      params.type = CommonTypes.POLL;
      params.title = pollTitle;
      params.pollOptions = { options: options };
    }
    axios
      .post(FEEDS, params, config)
      .then((response: any) => {
        if (response?.data?.statusCode == 200 && response?.data?.data != null) {
          setIsLoading(false);
          const temp_res = response.data?.data?.post ?? []
          console.log('temp_res', response.data.data)
          const temp = [temp_res, ...feed]
          setFeed?.(temp)
          onClose()
          logEventWeb('user_interaction', {
            interaction_type: 'add_post',
            id: String(temp_res._id),
            org_id: String(user?.organizationId),
            class_id: String(activeClassroom?._id),
            custom_user_id: String(user?._id),
          });
        } else {
          setIsLoading(false);
          showSnackbar(response?.message ?? 'Something went wrong. Please try later', 'error')
        }
      })
      .catch(err => {
        showSnackbar(err?.response?.data?.message
          ? err?.response?.data?.message
          : 'Something went wrong. Please try later', 'error')
        setIsLoading(false);
      });
  };

  const onClose = () => {
    handleClose?.()
    setPost('')
    setError('')
    setFiles([])
    setImageFile([])
    setUrls([])
    setInputVisible(false)
    onDeletePoll()
  }

  const handleMentionedUsers = (users: any) => {
    const uniqueArray = _.uniqBy(users, '_id');
    setMentionedUsers(uniqueArray);
  };


  const createPollClose = () => {
    setCreatePoll(false);
  }

  const onDeletePoll = () => {
    setPollOptions([]);
    setPollTitle('');
  };

  function isEmpty(): boolean {
    return imageFile.length === 0 && files.length === 0 && (urls?.length === 0 || urls === undefined);
  }

  const onPollOpen = () => {
    if (imageFile.length == 0 && files.length === 0 && urls?.length === 0) {
      setCreatePoll(true)
    }
  }

  const RenderModal = () => (
    <div className="create-post-modal">
      <div className='__header'>
        <h4>Create Post</h4>
        <button onClick={onClose}><IoIosClose /></button>
      </div>
      <div className='__oute_body_wrap'>
        <div className='body'>
          <WriteOnBoard2 marginBottom={'0px'}>
            <MentionPostTextArea
              handlePostChange={handlePostChange}
              setPost={setPost}
              post={post}
              handleMentionedUsers={handleMentionedUsers} />
          </WriteOnBoard2>
          {error && <p className='___errror'>{error}</p>}
          {options?.length > 0 && (
            <PollPreview
              pollTitle={pollTitle}
              options={options}
              setCreatePoll={setCreatePoll}
              onDelete={onDeletePoll}
            />
          )}
          {imageFile?.length > 0 &&
            <PostPhotoScroller
              setImageFile={setImageFile}
              files={files}
              imageFile={imageFile} />}
        </div>
        <URLInput
          urls={urls}
          setUrls={setUrls}
          inputVisible={inputVisible}
          setInputVisible={setInputVisible} />
        <AttachFiles
          fileInputRef={fileAttachmentRef}
          setFiles={setFiles}
          files={files}
          imageFile={imageFile} />
      </div>
      <div className='__buttons2'>
        {options?.length == 0 &&
          <div className='__link_container__'>
            <i
              onClick={onPollOpen}
              style={{ cursor: isEmpty() ? CursorType.Pointer : CursorType.NotAllowed, opacity: isEmpty() ? 1 : .3 }}
              className="fi fi-rr-square-poll-vertical" />
            <PhotoPicker
              imageFile={imageFile}
              files={files}
              setImageFile={setImageFile} />
            <i
              onClick={() => setInputVisible(!inputVisible)}
              style={{ cursor: CursorType.Pointer }}
              className="fi fi-rr-link"></i>
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  color: '#fff',
                  fontWeight: 400
                },
              }}
              onClick={() => fileAttachmentRef.current?.click()}
              badgeContent={files?.length ? files?.length > 9 ? '9+' : files?.length : 0}
              color="primary">
              <i style={{ cursor: CursorType.Pointer }} className="fi fi-rr-clip"></i>
            </Badge>
          </div>}
        <PrimaryButton
          disabled={
            post?.trim()?.length === 0 &&
            imageFile.length == 0 &&
            files.length === 0 &&
            urls?.length === 0 &&
            options?.length == 0
          }
          fullWidth={false}
          progress={percentage}
          isLoading={isLoading}
          onClick={onSubmit}>
          {CommonTypes.POST}
        </PrimaryButton>
      </div>

      <ModalView
        title={CreatePostModalData.CreatePoll}
        open={createPoll}
        handleClose={createPollClose}
        modalStyle={modalStyle}>
        <CreatePoll
          pollTitle={pollTitle}
          totalOptions={options?.length > 2 ? options?.length : 2}
          pollOptions={options}
          setPollTitle={setPollTitle}
          setPollOptions={setPollOptions}
          handleClose={createPollClose} />
      </ModalView>
    </div>
  )
  return (
    <ModalView
      open={open}
      handleClose={onClose}
      modalStyle={modalStyle}>
      {RenderModal()}
    </ModalView>
  );
};

export default CreatePostModal;
