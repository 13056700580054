import React, { useEffect, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { formatAMPM, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import moment from 'moment';
import { colors } from 'assets/styles/colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { todaysClassesBreakPoints } from '../../../assets/styles/swiperBreakPoints';
import { useNavigate } from 'react-router-dom';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { useCheckRole } from 'utils/customHooks';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { setUpdateTimeTable } from '../../../redux/slices/setClassroom/classRoomSlice';

const QuickAccess = () => {
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { subjectList } = classroomData || {};
    const [isSubjectEmpty, setSubjectEmpty] = useState(false);
    const navigate = useNavigate();
    const { metadata } = useMetadataContext();
    const { isInstructor } = useCheckRole()

    const currentTimeTable = async () => {
        const res = await fetchData(restAPIs.getTimeTable(activeClassroom?._id), navigate)
        if (res.statusCode === 200 && res.data) {
            dispatch(setUpdateTimeTable(res?.data))
        }
    }

    useEffect(() => {
        currentTimeTable()
    }, []);

    useEffect(() => {
        SwiperCore.use([Navigation, Pagination]);
        getTodaysClass();
    }, [localStorage.getItem('activeClassroom')]);

    const getTodaysClass = () => {
        const today = new Date();
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        const currentDayIndex = today.getDay();
        const temp_today = days[currentDayIndex];
        const isSubjectsEmpty = activeClassroom?.timeTable?.data?.every((item: any) => !item?.subjects || item?.subjects.length === 0);
        setSubjectEmpty(isSubjectsEmpty);

        const day_data =
            activeClassroom?.timeTable?.data?.find((item: any) => item?.day === temp_today)
                ?.subjects || [];

        const sortedData = day_data?.slice().sort((a: any, b: any) => {
            const dateA = new Date(a.startTime)?.getTime();
            const dateB = new Date(b.startTime)?.getTime();
            return dateA - dateB;
        });

        setData(sortedData);
        findActiveClassIndex(sortedData);
    };

    const getName = (subjectId: string) => {
        const latestSubjectArray = subjectList?.length > 0 ? subjectList : [];
        let name = '';
        const matchingSubject = latestSubjectArray.find(
            (subject: any) => subject._id === subjectId,
        );
        if (matchingSubject) {
            name = matchingSubject.name;
            return name;
        }
        return '';
    };

    function getTwentyFourHourTime(amPmString: any) {
        var d = new Date('1/1/2013 ' + amPmString);
        return d.getHours() + ':' + d.getMinutes();
    }

    const getActiveClass = (startDate: any, endDate: any) => {
        let status = false;
        // current time
        var temp_Time = new Date();
        var temp_currentTime = formatAMPM(temp_Time);
        var currentTime = getTwentyFourHourTime(temp_currentTime);

        // starting time
        var temp_strtTime = new Date(startDate);
        var start = formatAMPM(temp_strtTime);
        var startTime = getTwentyFourHourTime(start);

        // ending time
        var temp_endTime = new Date(endDate);
        var end = formatAMPM(temp_endTime);
        var endTime = getTwentyFourHourTime(end);
        var format = 'HH:mm';

        var time = moment(currentTime, format),
            beforeTime = moment(startTime, format),
            afterTime = moment(endTime, format);

        if (time.isBetween(beforeTime, afterTime)) {
            status = true;
        }
        return status;
    };

    const findActiveClassIndex = (sortedData: any) => {
        const currentTime = new Date();
        const currentFormattedTime = getTwentyFourHourTime(formatAMPM(currentTime));

        let activeClassIndex = sortedData.findIndex((item: any) => {
            const startTime = getTwentyFourHourTime(formatAMPM(new Date(item.startTime)));
            const endTime = getTwentyFourHourTime(formatAMPM(new Date(item.endTime)));
            return moment(currentFormattedTime, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'));
        });
        setActiveIndex(activeClassIndex);
    };

    if (!metadata?.timeTablePresent || isSubjectEmpty) {
        if (isInstructor()) {
            return (
                <div style={{ padding: '0 20px' }}>
                    <Typography style={style} variant="h3" className='home-form-title' fontSize={15}>
                        Today's Classes
                    </Typography>
                    <div onClick={() => navigate('/timetable')} className='todays-classes-empty-card_teacher'
                        style={{ backgroundColor: colors?.secondaryBackground, marginBottom: '20px' }}>
                        <Typography variant='h6' align='left'>
                            Click here to add timetable
                        </Typography>
                    </div>
                </div>)
        }
        else {
            return <div style={{ height: '20px' }} />
        }
    }

    return (
        <div style={{ padding: '0 20px' }}>
            <Typography style={style} variant="h3" className='home-form-title' fontSize={15}>
                Today's Classes
            </Typography>

            {
                isSubjectEmpty ? (
                    <div className='todays-classes-empty-card'
                        style={{ backgroundColor: colors?.secondaryBackground, marginBottom: '20px' }}>
                        <Typography variant='h6' align='left'>
                            Please setup your classroom timetable for your classmates{' '}
                            <a className="custom-link" onClick={() => navigate('/timetable')}>Setup Timetable</a>
                        </Typography>
                    </div>
                )
                    :
                    data?.length === 0 ? (
                        <div className='todays-classes-empty-card'
                            style={{ backgroundColor: colors?.secondaryBackground, marginBottom: '20px' }}>
                            <Typography variant='h6' align='left'>
                                No Classes Scheduled for Today.
                            </Typography>
                        </div>
                    ) : (
                        <Swiper
                            spaceBetween={'10px'}
                            className="mySwiper"
                            breakpoints={todaysClassesBreakPoints}
                            modules={[Navigation, Pagination]}
                            initialSlide={activeIndex}
                        >
                            <div className='todays-classes-card-container'>
                                {data?.map((item: any, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            {item?.subject?.name?.length > 0 && (
                                                <div style={{ backgroundColor: (activeIndex === index ? '#00b2f812' : '') }} className="todays-classes-card">
                                                    <div className="todays-classes-content">
                                                        <Typography variant='h4' className='todays-classes-content'>
                                                            <span className='todays-classes-index'>{index + 1}</span>
                                                            <Tooltip title={item?.subject?.name}>
                                                                <Typography variant='h4' fontSize={12} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {item?.subject?.name}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Typography>
                                                    </div>
                                                    <div className='todays-classes-time-container'>
                                                        <Typography className="todays-classes-time" variant='subtitle2'>
                                                            {formatAMPM(item.startTime) + '-' + formatAMPM(item.endTime)}
                                                        </Typography>
                                                        {getActiveClass(item.startTime, item.endTime) && (
                                                            <Typography align='left' variant='subtitle2' style={{ color: colors?.primary, fontWeight: '800' }}>Now</Typography>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    )
                                }
                                )}
                            </div>
                        </Swiper>
                    )}
        </div>
    );
};

export default QuickAccess;

const style = {
    marginTop: '15px',
    marginBottom: '15px'
};


// ;
