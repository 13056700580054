import { Event } from 'react-big-calendar';

// Define interfaces

//ChangeName
export interface ChangeNameFormState {
    firstName: string;
    lastName: string;
}

export interface ChangeNameFormErrorState {
    firstName: string;
    lastName: string;
    message: string | null;
}

//ChangePassword
export interface ChangePasswordFormState {
    password: string;
    confirmPassword: string;
    currentPassword: string;
}
export interface ChangePasswordFormErrorState {
    password: string;
    confirmPassword: string;
    currentPassword: string
}

//curriculam

export interface CurriculamTempModule {
    subjectId: string;
    sections?: any;
}

// Events

export interface CalendarEvent extends Event {
    title?: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    name?: string
    startTime?: any;
    endTime?: any;
}

// Login

export interface CreateUserProps {
    closeModal?: () => void;
    setType?: React.Dispatch<React.SetStateAction<string | null>>
    fromProfile?: boolean
}

export interface LoginProps {
    setType: React.Dispatch<React.SetStateAction<string | null>>
    university?: any
}

export interface ResetPasswordFormState {
    password: string;
    confirmPassword: string
}

export interface ResetPasswordFormErrorState {
    password: string;
    confirmPassword: string
    message: string | null
}

export interface SignInFormState {
    email: string;
    password: string;
  }

  export interface SignInFormErrorState {
    email: string;
    password: string;
    message: string | null
  }

  export interface SignUpFormState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string
  }

  export interface SignUpFormErrorState {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string
    message: string | null
  }

  export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface ReportAProblemFormErrorState {
  description: string;
}

export interface SubjectChange {
  target: {
      value: string;
  };
}

export interface DescChange {
  target: {
      value: string;
  };
}

export interface UniversityListProps {
  data: any;
}


// Create post Modal
export interface CreatePostModalProps{
    handleClose?: () => void;
    open: any;
    modalStyle?: any;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    createType?: string;
    setFeed?: React.Dispatch<React.SetStateAction<any>>
    feed?: any
    onRefresh?: () => void
}

export interface FileItem {
  file: File;
  type: string;
}

// Photo Picker

export interface PhotoPickerProps {
  imageFile?: any;
  setImageFile: React.Dispatch<React.SetStateAction<any>>;
  files?: any;
  setPreviewFiles?:React.Dispatch<React.SetStateAction<any>>
}

// Crop Picker

export interface CropPickerProps {
  imageFile?: any;
  setImageFile: React.Dispatch<React.SetStateAction<any>>;
  editItem?: any;
  files?: any;
  selected?:null|number
  setSelected: React.Dispatch<React.SetStateAction<null|number>>
  setImg?:React.Dispatch<React.SetStateAction<any>>
  img?:any
}

export interface CreateNoteModalProps {
  handleClose?: () => void;
  open: any;
  modalStyle?: any;
  handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  createType?: string;
  editItem?: any;
  addFile?: boolean;
  detailRefresh?: () => void
  loadMore?: (initialPage: boolean) => void;
  subject?: any
}

// Define initial states

//ChangeName
export const changeNameInitialForm: ChangeNameFormState = {
    firstName: '',
    lastName: '',
};

export const changeNameInitialError: ChangeNameFormErrorState = {
    firstName: '',
    lastName: '',
    message: ''
};

//ChangePassword
export const ChangePasswordInitialForm: ChangePasswordFormState = {
    password: '',
    confirmPassword: '',
    currentPassword: ''
}

export const ChangePasswordInitialError: ChangePasswordFormErrorState = {
    password: '',
    confirmPassword: '',
    currentPassword: ''
}

export const ResetPasswordInitialForm:ResetPasswordFormState = {
    password: '',
    confirmPassword: ''
  }

  export const ResetPasswordInitialError:ResetPasswordFormErrorState = {
    password: '',
    confirmPassword: '',
    message: ''
  }

  export const SignInInitialForm:SignInFormState = {
    email: '',
    password: '',
  }

  export const SignInInitialError:SignInFormErrorState = {
    email: '',
    password: '',
    message: ''
  }

  export const SignUpInitialForm:SignUpFormState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  }
  
  export const SignUpInitialError:SignUpFormErrorState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    message: ''
  }

  export const ReportAProblemInitialerrors:ReportAProblemFormErrorState = {
    description: ''
}
