// Core
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import PrimaryButton, { CreateButton, SecondryButton } from 'components/atoms/CustomButtons';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import DeleteModal from 'components/atoms/DeleteModal';
import CreateSubjectModal from 'components/atoms/CreateSubjectModal';

// Third-Party Libraries
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    SelectChangeEvent,
    Tooltip,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { Select, MenuItem, List, ListItem } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Utilities
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import {
    abbreviatedDays,
    CursorType,
    daysOfWeek,
    initialTimes,
    MODAL_STYTLE,
    restrictModalData,
    SnackBarMessages,
    StatusType,
    TimetableData
} from 'utils/constants';

// Redux Actions and State
import { setUpdateTimeTable } from '../../redux/slices/setClassroom/classRoomSlice';

// Styles and Constants
import { colors } from 'assets/styles/colors';
import './index.scss';
import { useCheckRole } from 'utils/customHooks';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import { set } from 'lodash';


const Timetable: React.FC = () => {

    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { isInstructor } = useCheckRole()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const subjects = classroomData?.subjectList
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [subjectList, setSubjectList] = useState<any>([])
    const [currentTime, setCurrentTime] = useState(dayjs());

    const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
    const [data, setData] = useState(activeClassroom.timeTable);

    const [originalData, setOriginalData] = useState(data);
    const [open, setOpen] = React.useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditTiming, setIsEditTiming] = useState(false);

    const [isLoadingTimetable, setIsLoadingTimeTable] = useState(false)
    const [isLoadingSession, setIsLoadingSession] = useState(false)
    const [isLoadingInitial, setIsLoadingInitial] = useState(false)
    const [isSubjectLoading, setSubjectLoading] = useState(false)

    const [isUpdated, setIsUpdated] = useState(false);
    const [initialData, setInitialData] = useState<any>({ timings: [], data: [] })
    const [isRestricted, setRestricted] = useState(false);
    const [openSubjectModal, setOpenSubjectModal] = useState<string>('');

    const [columnError, setColumnError] = useState<string | null>(null);
    const [rowError, setRowError] = useState<string | null>(null);


    useEffect(() => {
        currentTimeTable(true);
        currentSubjectList();
        const timer = setInterval(() => {
            setCurrentTime(dayjs());
        }, 60000);
        return () => clearInterval(timer);
    }, [subjects]);


    const currentSubjectList = async () => {
        setSubjectLoading(true)
        const res = await fetchData(restAPIs.getSubjects(activeClassroom?._id), navigate)
        setSubjectLoading(false)
        if (res?.data?.length > 0) {
            setSubjectList(res?.data)
        }
    }

    const currentTimeTable = async (disableResetActiveClass?: boolean) => {
        const res = await fetchData(restAPIs.getTimeTable(activeClassroom?._id), navigate)
        if (res.statusCode === 200 && res.data) {
                dispatch(setUpdateTimeTable(res?.data))
            setData(res?.data)
            setOriginalData(res?.data)
            setInitialData(res?.data)
            if(!res?.data?._id&&isInstructor()){
                onEditTimingClick()
            }
        }
    }

    const isCurrentTime = (startTime: any, endTime: any) => {
        const now = currentTime;
        return now.isAfter(dayjs(startTime)) && now.isBefore(dayjs(endTime));
    };

    const getCurrentDay = () => {
        return currentTime.format(TimetableData.CurrentTimeFormat).toLowerCase();
    };

    const currentDay = getCurrentDay();

    const handleSelect = (subjectId: string) => {
        setSelectedSubject(subjectId);
    };

    const handleCellClick = (dayIndex: number, timeIndex: number, sectionId: any) => {
        setData((prevData: any) => {
            // Create a deep copy of the previous data
            const newData = JSON.parse(JSON.stringify(prevData));

            // Ensure dayData exists at the given index
            const dayData = newData?.data?.[dayIndex];
            if (!dayData) return prevData;

            // Ensure subjects array exists
            if (!dayData.subjects) {
                dayData.subjects = [];
            }

            // Retrieve the current subject in this slot (if it exists)
            const currentSubjectIndex = dayData.subjects.findIndex(
                (subject: any) => subject.sectionId === sectionId
            );
            const selectedSubjectData = subjectList.find((subject: any) => subject._id === selectedSubject);

            if (selectedSubjectData) {
                if (currentSubjectIndex !== -1) {
                    const currentSubject = dayData.subjects[currentSubjectIndex];
                    if (currentSubject.subject._id === selectedSubjectData._id) {
                        // If the selected subject is the same as the current subject, remove it
                        dayData.subjects.splice(currentSubjectIndex, 1);
                    } else {
                        // Replace the current subject with the selected one
                        dayData.subjects[currentSubjectIndex] = {
                            startTime: newData.timings[timeIndex]?.startTime || null,
                            endTime: newData.timings[timeIndex]?.endTime || null,
                            subject: {
                                ...selectedSubjectData,
                                name: selectedSubjectData.subjectName,
                                subjectId: selectedSubjectData.subjectId || selectedSubjectData._id,
                                instructor: {
                                    name:
                                      selectedSubjectData.instructorDetails?.firstName +
                                      ' ' +
                                      selectedSubjectData.instructorDetails?.lastName,
                                    _id: selectedSubjectData.instructorId,
                                  },
                            },
                            sectionId: sectionId,
                        };
                    }
                } else {
                    // If there is no current subject, push the selected subject to the subjects array
                    dayData.subjects.push({
                        startTime: newData.timings[timeIndex]?.startTime || null,
                        endTime: newData.timings[timeIndex]?.endTime || null,
                        subject: {
                            ...selectedSubjectData,
                            name: selectedSubjectData.subjectName,
                            subjectId: selectedSubjectData.subjectId || selectedSubjectData._id,
                            instructor: {
                                name:
                                  selectedSubjectData.instructorDetails?.firstName +
                                  ' ' +
                                  selectedSubjectData.instructorDetails?.lastName,
                                _id: selectedSubjectData.instructorId,
                              },
                        },
                        sectionId: sectionId,
                    });
                }
            }

            // Check for changes in data
            if (JSON.stringify(newData) !== JSON.stringify(originalData)) {
                setIsUpdated(true);
            } else {
                setIsUpdated(false);
            }
            return newData;
        });
    };

    const formatTimeRange = (startTime: string, endTime: string) => {
        const start = dayjs(startTime);
        const end = dayjs(endTime);

        const startFormatted = start.format('hh:mm');
        const endFormatted = end.format('hh:mm A');

        const startPeriod = start.format('A');
        const endPeriod = end.format('A');

        if (startPeriod === endPeriod) {
            return `${startFormatted} - ${end.format('hh:mm A')}`;
        } else {
            return `${startFormatted} ${startPeriod} - ${endFormatted}`;
        }
        return `${startFormatted} ${startPeriod} `;
    };

    const onEditClick = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {


            if (subjectList?.length == 0) {
                setOpenSubjectModal(TimetableData.Subject)
            }
            else {
                setIsEdit(true);
                setIsEditTiming(false);
            }
        }
        else setRestricted(true)
    };

    const handleUpdateClick = async () => {
        const updatedTimetable = {
            classRoomId: activeClassroom?._id,
            noOfSessions: data.noOfSessions,
            noOfDays: data.noOfDays,
            timings: data.timings,
            data: data?.data?.map((day: any) => ({
                // _id: day._id,
                day: day.day,
                subjects: day.subjects?.map((sub: any, index: number) => {
                    if (sub) {
                        return {
                            sectionId: sub?.sectionId,
                            startTime: sub.startTime,
                            endTime: sub.endTime,
                            subject: {
                                name: sub?.subject?.name,
                                subjectId: sub.subject?._id ?? sub.subject?.subjectId,
                                ...(sub?.subject?.instructor?._id && { instructor: sub?.subject?.instructor })
                            }
                        };
                    } else {
                        return null;
                    }
                }).filter((subject: any) => subject !== null) // Filter out null subjects
            }))
        };

        // Here you can send the `updatedTimetable` to your backend using fetchData or any other method
        setIsLoadingTimeTable(true)
        const res = await fetchData(restAPIs.updateTimetable(data?._id, updatedTimetable), navigate)
        if (res.statusCode == 200) {
            currentTimeTable();
            showSnackbar(SnackBarMessages.TimetableUpdatedSuccessfully, StatusType.success)
        }
        else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
        setIsLoadingTimeTable(false)
        setIsUpdated(false);
        setIsEdit(false);
        setSelectedSubject(null)
    };

    const handleCancelClick = () => {
        setData(originalData);
        setIsUpdated(false);
        setIsEdit(false);
        setSelectedSubject(null)
    };

    // sessions
    const [sessions, setSessions] = useState<{
        startTime: dayjs.Dayjs;
        endTime: dayjs.Dayjs;
        _id: string;
        startIsValid: boolean;
        endIsValid: boolean;
        startErrorMessage: string;
        endErrorMessage: string;
    }[]>([]);

    const [sessionCount, setSessionCount] = useState<number>(initialData?.noOfSessions);
    const [days, setDays] = useState<string[]>(initialData?.data?.map((day: any) => daysOfWeek[abbreviatedDays.indexOf(day.day)]));

    useEffect(() => {
        initilSessionSet();
    }, [initialData]);

    const initilSessionSet = () => {
        const newSessions = initialData?.timings?.map((timing: any, index: any) => {
            const start = dayjs(timing.startTime);
            const end = dayjs(timing.endTime);
            return {
                startTime: start,
                endTime: end,
                _id: timing._id,
                startIsValid: true,
                endIsValid: true,
                startErrorMessage: '',
                endErrorMessage: '',
            };
        });

        setSessions(newSessions);
    }

    const generateSessions = (count: number) => {
        const newSessions = initialTimes?.slice(0, count)?.map((startTime, index) => {
            const start = dayjs(startTime, 'hh:mm a');
            const end = dayjs(start).add(1, 'hours');
            return {
                startTime: start,
                endTime: end,
                _id: (index + 1).toString(),
                startIsValid: true,
                endIsValid: true,
                startErrorMessage: '',
                endErrorMessage: '',
            };
        });

        setSessions(newSessions);
    };

    const updateSessionTime = (id: string, time: dayjs.Dayjs, type: TimetableData.start | TimetableData.end) => {
        setSessions(prevSessions =>
            prevSessions?.map(session => {
                if (session._id === id) {
                    const updatedSession = { ...session };
                    if (type === 'start') {
                        updatedSession.startTime = time;
                        if (time.isBefore(updatedSession.endTime)) {
                            updatedSession.startIsValid = true;
                            updatedSession.startErrorMessage = '';
                            updatedSession.endIsValid = true;
                            updatedSession.endErrorMessage = '';
                        } else {
                            updatedSession.startIsValid = false;
                            updatedSession.startErrorMessage = TimetableData.StartTimeMustBeBeforeEndTime;
                        }
                    } else {
                        updatedSession.endTime = time;
                        if (time.isAfter(updatedSession.startTime)) {
                            updatedSession.endIsValid = true;
                            updatedSession.endErrorMessage = '';
                            updatedSession.startIsValid = true;
                            updatedSession.startErrorMessage = '';
                        } else {
                            updatedSession.endIsValid = false;
                            updatedSession.endErrorMessage = TimetableData.EndTimeMustBeAfterStartTime;
                        }
                    }
                    return updatedSession;
                }
                return session;
            })
        );
    };

    const isFormValid = sessions?.every(session => session.startIsValid && session.endIsValid);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        setColumnError(null)
        setDays(event.target.value as string[]);
    };

    const generateOutput = async () => {
        days.sort((a, b) => abbreviatedDays.indexOf(a.toLowerCase().slice(0, 3)) - abbreviatedDays.indexOf(b.toLowerCase().slice(0, 3)));
        const output = {
            classRoomId: activeClassroom?._id,
            noOfSessions: sessions?.length,
            noOfDays: days?.length,
            data: days?.map(day => ({
                day: abbreviatedDays[daysOfWeek.indexOf(day)],
                subjects: []
            })),
            timings: sessions?.map(session => ({
                startTime: session.startTime.toISOString(),
                endTime: session.endTime.toISOString()
            }))
        };
        setIsLoadingSession(true)
        const res = data?._id ?
            await fetchData(restAPIs.updateTimetable(data?._id, output), navigate) :
            await fetchData(restAPIs.createTimetable(output), navigate);
        setIsLoadingSession(false)
        if (res.statusCode === 200) {
            currentTimeTable();
            handleClose();
            setIsEdit(false)
            setIsEditTiming(false);
            showSnackbar(SnackBarMessages.TimetableUpdatedSuccessfully, StatusType.success);
        } else {
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error);
        }
    };

    const onEditTimingClick = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {
            setIsEdit(false)
            setIsEditTiming(true);
        }
        else setRestricted(true)
    };

    const handleCancelTimingClick = () => {
        setIsEditTiming(false);
        setSessionCount(initialData?.noOfSessions)
        initilSessionSet();
        setDays(initialData?.data?.map((day: any) => daysOfWeek[abbreviatedDays.indexOf(day.day)]))
    };



    const handleClose = () => {
        setOpen(false);
    };

    const openEditModal=()=>{
        if(days?.length===0){
            setColumnError('* Required')
        }
        else if(!sessionCount){
            setRowError('* Required')
        }
        else{
         setOpen(true)
        }
    }

    return (
        <GridContainer pageTitle={'Timetable'} leftGridPartition={3 / 4}>

            <Backdrop
                sx={{ color: colors.white, zIndex: 1, background: colors.timeTableBackground }}
                open={isLoadingInitial}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ marginBottom: '15vh' }}>
                {isInstructor() &&
                    <div className='create-button-wrapper __two_btns'>
                        <CreateButton
                            isLoading={isSubjectLoading}
                            disabled={isSubjectLoading}
                            bgColor={isEdit ? colors.createButtonColor : colors.timeTableColor}
                            color={isEdit ? colors.white : colors.createButtonColor}
                            onClick={onEditClick}>
                            {TimetableData.EditTimetable}
                        </CreateButton>
                        <CreateButton
                            bgColor={isEditTiming ? colors.createButtonColor : colors.timeTableColor}
                            color={isEditTiming ? colors.white : colors.createButtonColor}
                            onClick={onEditTimingClick}>
                            {TimetableData.EditTimingsSessions}
                        </CreateButton>
                    </div>}
                <div>
                    {!isEditTiming && <fieldset disabled={!isEdit}>
                        {isEdit && (
                            <div className="single-select-container">
                                {subjectList?.map((subject: any) => (
                                    <Tooltip title={subject.subjectName} key={subject._id}>
                                        <div
                                            onClick={() => handleSelect(subject._id)}
                                            className={`single-select-item ${selectedSubject === subject._id ? 'selected' : ''}`}
                                        >
                                            {subject.subjectName}
                                        </div>
                                    </Tooltip>
                                ))}
                            </div>
                        )}
                        <div className="timetable-container">
                            {data?.data&&
                            <Paper elevation={3} className="timetable-paper">
                                <Grid container style={{ flexWrap: 'nowrap' }}>
                                    <Grid item xs={2} className="timetable-header">
                                        <Typography variant="h6" className='day_text'>{TimetableData.Timing}</Typography>
                                    </Grid>
                                    {data?.data?.map((day: any, index: any) => (
                                        <Grid item xs={12 / (data.noOfDays)} key={index} className={`timetable-header ${day.day === currentDay ? 'current-day' : ''}`}>
                                            <Typography className='day_text' variant="h6">{day.day.toUpperCase()}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                {data?.timings?.map((time: any, timeIndex: any) => (
                                    timeIndex < data?.noOfSessions &&
                                    <Grid container key={time._id} className={`timetable-row ${isCurrentTime(time.startTime, time.endTime) ? 'current-time' : ''}`}>
                                        <Grid item xs={2} className="timetable-timing">
                                            <Typography variant="body2" className="__time">
                                                {formatTimeRange(time.startTime, time.endTime)}
                                            </Typography>
                                        </Grid>
                                        {data?.data?.map((day: any, dayIndex: any) => {
                                            const sectionId = (timeIndex * data.noOfDays) + dayIndex + 1 + timeIndex;
                                            const subjectData = day.subjects.find((sub: any) => sub?.sectionId === sectionId);
                                            if (subjectData && subjectList?.length > 0) {

                                                const subject = subjectData.subject;
                                                return (
                                                    <Grid item xs={12 / (data.noOfDays)} key={dayIndex} className={`timetable-cell ${day.day === currentDay ? 'current-day-cell' : ''}`} onClick={() => handleCellClick(dayIndex, timeIndex, sectionId)}>
                                                        <div>
                                                        <div className="subject">
                                                            {subject ? subject.name : ''}
                                                        </div>
                                                        {subject?.instructor?._id&&
                                                        <div className="subject">
                                                            {subject?.instructor?.name}
                                                        </div>}
                                                        </div>
                                                    </Grid>
                                                );
                                            } else {
                                                return (
                                                    <Grid item xs={12 / (data.noOfDays)} key={dayIndex} className={`timetable-cell ${day.day === currentDay ? 'current-day-cell' : ''}`} onClick={() => handleCellClick(dayIndex, timeIndex, sectionId)}>
                                                        <div className="subject">
                                                            {isInstructor()?'+':''}
                                                        </div>
                                                    </Grid>
                                                );
                                            }
                                        })}
                                    </Grid>
                                ))}
                            </Paper>}
                            {!data?.data&&!isInstructor()&&<div className='no_data'>No timetable available</div>}
                            <div className='__footer-button'>
                                {isEdit && <SecondryButton
                                    fullWidth={false}
                                    onClick={handleCancelClick}
                                    bgColor={colors.white}
                                >
                                    {TimetableData.Cancel}
                                </SecondryButton>}
                                {isEdit && isUpdated && <PrimaryButton
                                    fullWidth={false}
                                    onClick={handleUpdateClick}
                                    disabled={!isUpdated}
                                    isLoading={isLoadingTimetable}
                                >
                                    {TimetableData.Update}
                                </PrimaryButton>}
                            </div>
                        </div>

                    </fieldset>}
                </div>
                {isEditTiming && !isEdit && <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className='__days_list_session'>
                            <h1 className='__session_head'>
                                {TimetableData.DaysAndSessions}
                            </h1>
                            <div className='__session__'>
                                <div style={{ textAlign: 'left', maxWidth: '25vw' }}>
                                    <Typography variant="h4" gutterBottom>
                                        {TimetableData.NumberOfColumnsDays}
                                    </Typography>
                                    <Select
                                        multiple
                                        value={days}
                                        onChange={handleChange}
                                        style={{ width: '100%', flex: '1', height: '40px' }}
                                    >
                                        {daysOfWeek?.map((day) => (
                                            <MenuItem key={day} value={day}>
                                                {day}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className='error'>{columnError}</div>
                                </div>
                                <div style={{ textAlign: 'left', maxWidth: '100%' }}>
                                    <Typography variant="h4" gutterBottom>
                                        {TimetableData.NumberOfRowSession}
                                    </Typography>
                                    <Select
                                        value={sessionCount}
                                        onChange={(event) => {
                                            setRowError(null)
                                            setSessionCount(event.target.value as number);
                                            generateSessions(event.target.value as number);
                                        }}
                                        style={{ width: '100%', flex: '1', height: '40px' }}
                                    >
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <MenuItem key={i + 1} value={i + 1}>
                                                {i + 1}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div className='error'>{rowError}</div>
                                </div>
                            </div>
                            <List>
                                {sessions?.map((session: any, index: any) => (
                                    index < sessionCount &&
                                    <ListItem key={session._id} style={{ gap: '20px', alignItems: 'flex-start', padding: '0', marginBottom: '16px' }} >
                                        <h3 style={{ fontWeight: '500', margin: '0', marginTop: '10px', minWidth: '100px' }}>Session : {index + 1}</h3>
                                        <div className='__time_pickers___'>
                                            <TimePicker
                                                label="Start Time"
                                                value={session.startTime}
                                                onChange={(date) => updateSessionTime(session._id, dayjs(date), TimetableData.start)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !session.startIsValid ? session.startErrorMessage : '',
                                                        FormHelperTextProps: {
                                                            style: { color: 'red' },
                                                        },
                                                    },
                                                }}
                                                className="__custom-timepicker"
                                            />
                                            <TimePicker
                                                label="End Time"
                                                value={session.endTime}
                                                onChange={(date) => updateSessionTime(session._id, dayjs(date), TimetableData.end)}
                                                slotProps={{
                                                    textField: {
                                                        helperText: !session.endIsValid ? session.endErrorMessage : '',
                                                        FormHelperTextProps: {
                                                            style: { color: 'red' },
                                                        },
                                                    },
                                                }}
                                                className="__custom-timepicker"
                                            />
                                        </div>
                                    </ListItem>
                                ))}
                            </List>
                            <div className='__footer-button'>
                                <SecondryButton
                                    fullWidth={false}
                                    onClick={handleCancelTimingClick}
                                    bgColor={colors.white}
                                >
                                    {TimetableData.Cancel}
                                </SecondryButton>
                                <PrimaryButton
                                    fullWidth={false}
                                    disabled={!isFormValid}
                                    onClick={openEditModal}
                                >
                                    {TimetableData.Update}
                                </PrimaryButton>

                                <Dialog
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {TimetableData.Confirm}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {TimetableData.ByChangingSessions}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <a style={{ fontSize: '15px', cursor: CursorType.Pointer }} onClick={handleClose}>{TimetableData.Cancel}</a>
                                        <PrimaryButton
                                            fullWidth={false}
                                            onClick={generateOutput}
                                            isLoading={isLoadingSession}
                                        >
                                            {TimetableData.Confirm}
                                        </PrimaryButton>

                                    </DialogActions>
                                </Dialog>

                            </div>
                        </div>
                    </LocalizationProvider>
                </div>}
                <DeleteModal
                    open={isRestricted}
                    onSubmit={() => setRestricted(false)}
                    data={restrictModalData}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setRestricted(false)}
                />
                <CommonDeleteModal
                    open={openSubjectModal === TimetableData.Subject}
                    buttonLabel={'Ok'}
                    description={`There is no subject in this classroom.`}
                    title={'Sorry no subject found!'}
                    handleClose={() => setOpenSubjectModal('')}
                    handleSubmit={() => setOpenSubjectModal('')}
                />
                {/* <CreateSubjectModal
                    disableReroute={true}
                    open={openSubjectModal == TimetableData.Subject}
                    modalStyle={MODAL_STYTLE}
                    createType={openSubjectModal}
                    handleClose={() => setOpenSubjectModal('')} /> */}
            </div>
        </GridContainer>
    );
};

export default Timetable;