// Core
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Third-Party Libraries
import { IoIosClose } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegFileVideo } from "react-icons/fa";
import { FaRegFileWord } from "react-icons/fa";
import { AiOutlineFilePdf } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { Select, MenuItem, Typography } from '@mui/material';

// Components
import ModalView from '../ModalView';
import PrimaryButton from '../CustomButtons';
import { useSnackbar } from '../SnackbarProvider';
import CreateSubjectModal from '../CreateSubjectModal';

// Utilities
import { getFileType, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { fetchData } from "utils/fetch";
import { DOCUMENT_PATH, NOTE_PATH, restAPIs } from "utils/restAPIs"
import { useFetchClassRoomItems } from 'utils/customHooks';
import { NOTE_TITLE, DOCUMENT_TITLE, CreateNoteModalData, StatusType, SnackBarMessages, MODAL_STYTLE } from 'utils/constants';
import { readFileAsBinary, uploadFile } from 'utils/filesupload';
import { CreateNoteModalProps } from 'utils/types';

// Styles and Constants

import './index.scss'
import { colors } from 'assets/styles/colors';

// Context Api
import { useMetadataContext } from 'contextApi/MetadataContext';
import { logEventWeb } from 'utils/firebase';

const CreateNoteModal: React.FC<CreateNoteModalProps> = (props) => {

    const {
        open,
        detailRefresh,
        handleClose,
        modalStyle,
        createType,
        editItem,
        addFile,
        loadMore,
        subject
    } = props

    const navigate = useNavigate()
    const { showSnackbar } = useSnackbar();
    const { setToogleDrawer } = useMetadataContext();
    const { fetchNotes, fetchDocuments, fetchMetaData } = useFetchClassRoomItems()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const activeClassroom = loadActiveClassroomLocalStorage()
    const userObject = loadUserFromLocalStorage();
    const user = userObject?.userDetails
    const subjects = classroomData?.subjectList
    const [name, setName] = useState('');
    const [selectedSubject, setSelectedSubject] = useState<any>(createType === CreateNoteModalData.Document ? "" : subjects?.[0]);
    const [files, setFiles] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);
    const [fileError, setFileError] = useState<any>(null);
    const [percentage, setPercentage] = useState(0);
    const [openSubjectModal, setOpenSubjectModal] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const tempPath = createType === CreateNoteModalData.Document ? DOCUMENT_PATH : NOTE_PATH

    const path = tempPath.replace(/{orgId}/g, user?.organizationId).replace(
        /{classRoomId}/g,
        activeClassroom?._id,
    );

    useEffect(() => {
        if (editItem) {
            setName(editItem.title)
            setSelectedSubject({ _id: editItem.subjectId, subjectName: editItem.subjectName })
            setFiles(editItem.files)
        }
    }, [editItem, addFile]);

    useEffect(() => {
        if (!editItem) {
            if(subject){
                setSelectedSubject(subject)
            }
            else if (createType !== CreateNoteModalData.Document) {
                setSelectedSubject(subjects?.[0])
            }
            else {
                setSelectedSubject("")
            }
        }
    }, [createType,subject]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const maxLength = createType !== CreateNoteModalData.Document ? NOTE_TITLE : DOCUMENT_TITLE;
        if (event.target?.value?.length < maxLength) {
            setName(event.target.value);
            setError(null)
        }
        else {
            setError(CreateNoteModalData.MaximumLengthExceeds)
        }
    }

    const handleSubjectChange = (event: any) => {
        const selectedId = event.target.value;
        const selected = subjects?.find((subject: any) => subject?._id === selectedId);
        if (selected) {
            setSelectedSubject(selected);
        }
        else if (event.target.value === CreateNoteModalData.None) {
            setSelectedSubject(CreateNoteModalData.Empty);
        }
    };

    const onClose = () => {
        handleClose?.()
        setPercentage(0)
        setName('')
        setFileError('')
        setError(null)
        setSelectedSubject(editItem?.subjectId ?
            editItem.subjectId :
            createType === CreateNoteModalData.Document ?
                CreateNoteModalData.Empty :
                subjects?.[0])
        setFiles([])
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles = Array.from(event.target.files);
            const fileItems = newFiles.map(file => {
                const fileType = getFileType(file);
                return { file, type: fileType };
            });
            setFiles((prevFiles: any) => [...prevFiles, ...fileItems]);
        }
        event.target.value = CreateNoteModalData.Empty;
    };

    const handleDeleteFile = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles.splice(index, 1);
        setFiles(updatedFiles);
    };

    const onSuccess = () => {
        if (createType === CreateNoteModalData.Document) {
            fetchDocuments(activeClassroom?._id)
            //console.log('1111')
        }
        else {
            // console.log('222')
            // loadMore ? loadMore?.(true) :
                fetchNotes(activeClassroom?._id)
        }
        fetchMetaData()
        showSnackbar(`${createType} ${editItem ?
            CreateNoteModalData.Updated :
            CreateNoteModalData.Created}`, StatusType.success)
        setIsLoading(false)
        onClose()
        if (detailRefresh) {
            detailRefresh()
        }
        else {
            navigate(createType === CreateNoteModalData.Document ? `/documents` : `/notes`)
        }
        setToogleDrawer(false)
    }


    const onSubmit = async () => {
        if (name?.trim()?.length > 0) {
            const uploadedData = [...files]
            let tempFiles = [];
            setIsLoading(true);
            if (uploadedData?.length > 0) {
                const time = new Date();
                const filesArray = Array.from(uploadedData);
                for (let index = 0; index < filesArray.length; index++) {
                    const f = filesArray[index].file ?? filesArray[index];
                    setPercentage(index / filesArray.length * 100)
                    if (files?.length < 10) {
                        if (f.url) {
                            const postImageItem = {
                                url: f.url,
                                uniqueFileName: f.uniqueFileName,
                                type: f.type,
                                size: f.size,
                                name: f.name,
                                id: f._id
                            };
                            tempFiles.push(postImageItem);
                        }
                        else {
                            const nameParts = f.name?.split('.');
                            const extension = nameParts ? `.${nameParts[nameParts.length - 1]}` : '';
                            const uniqueFilename = `${Math.random().toString(36).substring(7)}${time.getTime().toString()}${extension}`.replace(/[%\s]/g, '');
                            const fileType = f.type;
                            const filePath = `${path}${uniqueFilename}`;
                            const binaryBlob = await readFileAsBinary(f);
                            const downloadUrl = await uploadFile({ filePath, fileType, file: binaryBlob });
                            const postImageItem = {
                                url: downloadUrl,
                                uniqueFileName: uniqueFilename,
                                type: fileType,
                                size: f.size,
                                name: f.name,
                            };
                            tempFiles.push(postImageItem);
                        }
                    }
                    else {
                        setFileError(CreateNoteModalData.MaxFilesLimitExceeded)
                        showSnackbar(CreateNoteModalData.MaxLimitFilesExceeded, StatusType.warning)
                        setIsLoading(false);
                        return null
                    }
                };
            }
            let params: any = {};
            params.files = []
            if (tempFiles?.length > 0) {
                tempFiles.forEach((file: any) => {
                    params.files?.push({
                        url: file.url,
                        type: file.type,
                        name: file.name,
                        size: file.size,
                        uniqueFileName: file.uniqueFileName,
                        id: file.id
                    });
                });
            }
            if (!editItem) {
                params.classRoomId = activeClassroom?._id;
            }
            params.subjectId = selectedSubject?._id;
            params.title = name?.trim();
            const res = createType === CreateNoteModalData.Document ?
                editItem ? await fetchData(restAPIs.getupdateDocuments(editItem._id, params), navigate) :
                    await fetchData(restAPIs.getAddDocument(params), navigate) :
                editItem ? await fetchData(restAPIs.getupdateNote(editItem._id, params), navigate) :
                    await fetchData(restAPIs.getAddNote(params), navigate)
            if (res.statusCode === 200 && res.data) {
                onSuccess()
                if (createType === CreateNoteModalData.Document && !editItem) {
                    logEventWeb('user_interaction', {
                        interaction_type: 'add_document',
                        id: String(res.data._id),
                        org_id: String(user?.organizationId),
                        class_id: String(activeClassroom?._id),
                        custom_user_id: String(user?._id)
                    });
                }
                if (createType !== CreateNoteModalData.Document && !editItem) {
                    logEventWeb('user_interaction', {
                        interaction_type: 'add_note',
                        id: String(res.data._id),
                        org_id: String(user?.organizationId),
                        class_id: String(activeClassroom?._id),
                        custom_user_id: String(user?._id)
                      });
                }
            }
                else {
                    setIsLoading(false)
                    showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
                }
            }
            else {
                setError('* Required')
            }
        }
        const RenderModal = () => (
            <div className="create-note-modal">
                <div className='__header'>
                    <h4>
                        {addFile ? 'Upload files' : editItem ?
                            CreateNoteModalData.Edit :
                            CreateNoteModalData.Create} {!addFile ? createType : ''}
                    </h4>
                    <button onClick={onClose}><IoIosClose /></button>
                </div>
                <div className="file-upload-container">
                    <div className="input-group">
                        <label>{CreateNoteModalData.Name}</label>
                        <input
                            disabled={addFile}
                            value={name}
                            type="text"
                            onChange={handleNameChange}
                            placeholder={`${createType} name`} />
                        <Typography
                            sx={{ color: 'red' }}
                            variant='subtitle2'>
                            {error}
                        </Typography>
                    </div>

                    {subjects?.length > 0 && <label>{createType === CreateNoteModalData.Document ? CreateNoteModalData.SelectSubjectOptional : CreateNoteModalData.SelectSubject}</label>}
                    {subjects?.length > 0 ?
                        <div className="__session__note">
                            <Select
                                className='select'
                                disabled={addFile}
                                value={selectedSubject?._id ?? CreateNoteModalData.None}
                                onChange={handleSubjectChange} >
                                {createType == CreateNoteModalData.Document &&
                                    <MenuItem value={CreateNoteModalData.None}>{CreateNoteModalData.Nones}</MenuItem>}
                                {subjects?.map((subject: any) => (
                                    <MenuItem key={subject?._id} value={subject?._id}>
                                        {subject?.subjectName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div> :
                        <div className="subject_create_button">
                            {CreateNoteModalData.NoSubjectAddedYet}
                            {/* <a onClick={() => setOpenSubjectModal(CreateNoteModalData.Subject)}
                            style={{ fontWeight: '600' }}>
                            {CreateNoteModalData.ClickHere}
                        </a>
                        {CreateNoteModalData.ToCreateSubject} */}
                        </div>
                    }
                    {(!editItem || addFile) &&
                        <div className="file-upload-section">
                            <span>{files.length} {CreateNoteModalData.Files}</span>
                            <label className="file-upload-button">
                                {CreateNoteModalData.AddFile}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    multiple
                                    onChange={handleFileChange} />
                            </label>
                        </div>}

                    {(!editItem || addFile) && files.length > 0 && (
                        <ul className="file-list">
                            {files.map((fileItem: any, index: number) => {
                                return (
                                    <li key={index}>
                                        {(fileItem.type === 'Image' || fileItem.type === 'image/jpeg') ? (
                                            <div className="file-preview">
                                                <img
                                                    style={{ objectFit: 'contain' }}
                                                    src={fileItem?.url ?? URL.createObjectURL(fileItem.file)}
                                                    alt="Preview" />
                                            </div>
                                        ) : fileItem.type === 'PDF' ? (
                                            <AiOutlineFilePdf style={iconStyle} size={26} className="file-icon" />
                                        ) : fileItem.type === CreateNoteModalData.Document ? (
                                            <IoDocumentTextOutline style={iconStyle} size={24} className="file-icon" />
                                        ) : fileItem.type === 'Video' ? (
                                            <FaRegFileVideo style={iconStyle} size={24} className="file-icon" />)
                                            : <FaRegFileWord style={iconStyle} size={24} className="file-icon" />}
                                        <div className="file-details">
                                            <div className="file-name">{fileItem.file?.name ?? fileItem?.name}</div>
                                            <MdClose
                                                style={{ cursor: 'pointer' }}
                                                color={colors.secondary}
                                                size={16}
                                                onClick={() => handleDeleteFile(index)} />
                                        </div>
                                    </li>
                                )
                            }
                            )}
                        </ul>
                    )}
                </div>
                <Typography
                    sx={{ color: 'red', marginRight: '25px' }}
                    align='right'
                    variant='subtitle2'>
                    {fileError}
                </Typography>
                <div className='modal-footer-button'>
                    <PrimaryButton
                        fullWidth={false}
                        disabled={(createType !== CreateNoteModalData.Document && subjects?.length === 0) || isLoading}
                        progress={(editItem && !addFile) ? null : percentage}
                        onClick={onSubmit}
                        isLoading={isLoading}>
                        {addFile ?
                            CreateNoteModalData.UploadFiles :
                            editItem ?
                                CreateNoteModalData.Update :
                                CreateNoteModalData.Create} {!addFile ?
                                    createType :
                                    CreateNoteModalData.Empty}
                    </PrimaryButton>
                </div>
            </div>
        )

        return (
            <div>
                <ModalView
                    open={open && openSubjectModal !== CreateNoteModalData.Subject}
                    handleClose={onClose}
                    modalStyle={modalStyle}>
                    {RenderModal()}
                </ModalView>
                <CreateSubjectModal
                    disableReroute={true}
                    open={openSubjectModal === CreateNoteModalData.Subject}
                    modalStyle={MODAL_STYTLE}
                    createType={openSubjectModal}
                    handleClose={() => setOpenSubjectModal('')} />
            </div>
        )
    };

    export default CreateNoteModal;

    const iconStyle = {
        width: '25px',
        marginRight: '10px'
    }
