// Core
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import GridContainer from 'components/molecules/GridContainer';
import SubjectsFiles from 'components/atoms/SubjectsFiles';
import NoNotes from 'components/atoms/NoNotes';
import { CreateButton } from 'components/atoms/CustomButtons';
import CreateSubjectModal from 'components/atoms/CreateSubjectModal';
import ListActionMenu from 'components/atoms/ListActionMenu';
import DeleteModal from 'components/atoms/DeleteModal';
import { useSnackbar } from 'components/atoms/SnackbarProvider';

// Third-Party Libraries
import { CircularProgress, Typography } from '@mui/material';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Utilities
import { useCheckRole, useFetchClassRoomItems } from 'utils/customHooks';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import {
    CreateTypes,
    MODAL_STYTLE,
    restrictModalData,
    SnackBarMessages,
    StatusType,
    SubjectsData
} from 'utils/constants';

const Subjects: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { fetchSubject, fetchMetaData } = useFetchClassRoomItems()
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const {isInstructor}= useCheckRole()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isSubjectLoading } = classroomData
    const [subjectList, setsSubjects] = useState(classroomData?.subjectList);
    const [createType, setCreateType] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [editItem, setEditItem] = useState<any>(null);
    const curriculum = classroomData.curriculum
    const [selected, setSelected] = useState<number>(0)
    const [isRestricted, setRestricted] = useState(false);

    const getSections = (id: any) => {
        let count = 0;
        if (curriculum?.length > 0) {
            curriculum.map((item: any) => {
                if (item.subjectId == id) {
                    count = item.sections.length;
                }
            });
        }
        return count;
    };

    const onDelete = async () => {
        setIsLoading(true)
        const res = await fetchData(restAPIs.getSubjectsDelete(subjectList?.[selected]?._id), navigate)
        if (res.status == 200) {
            showSnackbar(SnackBarMessages.SubjectDeletedSuccessfully, StatusType.success)
            fetchSubject(activeClassroom?._id)
            fetchMetaData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }
    const onEdit = () => {
        setEditItem(subjectList?.[selected])
        setCreateType(CreateTypes.Subject)
    }

    const onCreateNew = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {
            setEditItem(null)
            setCreateType(CreateTypes.Subject)
        }
        else setRestricted(true)
    }

    useEffect(() => {
        setsSubjects(classroomData?.subjectList)
    }, [classroomData?.subjectList]);

    return (
        <GridContainer
            pageTitle={SubjectsData.Subjects}
            right={<SubjectsFiles subjects={subjectList?.[selected]}
                type={SubjectsData.Subjects}
                getSections={getSections} />}
            leftGridPartition={subjectList?.length == 0 ? 1 : 1 / 2.5}>
                
            <div style={{ marginBottom: '20vh' }}>
                {subjectList?.map((item: any, index: number) => {
                    const data = [
                        `${item?.notesCount} ${item?.notesCount == 1 ? SubjectsData.Note : SubjectsData.Notes}`,
                        `${item?.documentsCount} ${item?.documentsCount == 1 ? SubjectsData.Document : SubjectsData.Documents} `,
                        `${item?.curriculumDetails?.sections?.length??0} ${item?.curriculumDetails?.sections?.length == 1 ? SubjectsData.Session : SubjectsData.Sessions}`]
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>

                            <div className="title-note-container">
                                <Typography fontWeight={500} fontSize={'.8rem'} variant='h4' key={index}>
                                    {item.subjectName}
                                </Typography>
                            </div>
                            <div style={{ gap: '5px' }} className='notes-sub-container'>
                                {data?.map((items) => (
                                    <Typography fontSize={'10px'} className='subjects-count-name'>
                                        {items}
                                    </Typography>
                                ))}
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <SubjectsFiles subjects={subjectList[selected]}
                                    type={SubjectsData.Subjects}
                                    getSections={getSections} />
                            </div>
                            <div className='line-separator' />
                        </div>
                    );
                })}
                {isSubjectLoading ?
                    <div className='loader-container'>
                        <CircularProgress size={15} sx={{ color: colors?.primary }} />
                    </div>:subjectList?.length == 0? <NoNotes type={SubjectsData.Subjects} />:null}
                <CreateSubjectModal
                    open={createType == CreateTypes.Subject}
                    modalStyle={MODAL_STYTLE}
                    editItem={editItem}
                    createType={createType}
                    handleClose={() => {
                        setCreateType('')
                        setEditItem(null)
                    }} />
                <DeleteModal
                    open={isRestricted}
                    onSubmit={() => setRestricted(false)}
                    data={restrictModalData}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setRestricted(false)}
                />
                {/* {subjectList?.length == 0 &&
                    <NoNotes type={SubjectsData.Subjects} />} */}
            </div>
        </GridContainer>
    )
};

export default Subjects;
