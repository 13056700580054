import { buildGetEndpoint } from "./commonUtils";
//export const HOST = "https://stgapi-org.classteam.io/api/";
export const HOST ='https://api-org.classteam.io/api/';
export const API_VERSION = 'v1'


export const FEEDS = HOST + `post/${API_VERSION}/create`;
export const S3UPLOAD = HOST + 'misc/s3-upload?path=';

export const CLASS_JOIN = 'https://join.classteam.io/';

export const PRE_SINGED_URL = `${HOST}files/signed-url`;
export const POST_PATH = 'org/{orgId}/classroom/{classRoomId}/post/';
export const NOTE_PATH = 'org/{orgId}/classroom/{classRoomId}/note/';
export const DOCUMENT_PATH = 'org/{orgId}/classroom/{classRoomId}/document/';
export const ISSUE_PATH = 'org/{orgId}/classroom/{classRoomId}/issue/';

export const restAPIs = {

  getSignIn: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/${API_VERSION}/account-login`
  }),
  getSignUp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/signup`
  }),
  getPhoneOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/send-phone-otp`
  }),
  getLoginOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/login-otp`
  }),
  getOtpVerify: (otp: any,) => ({
    method: 'get',
    endpoint: `${HOST}auth/verify?token=${otp}`
  }),
  putUserDetails: (body: any,) => ({
    method: 'put',
    body,
    endpoint: `${HOST}user/${API_VERSION}/user-details`
  }),
  getUserImage : (id:any,formData: any,) => ({
    method: 'post',
    formData,
    endpoint: `${HOST}misc/s3-upload?path=user/${id}/user-image`
  }),
  getClassRoomList: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}class-room/suggest`,body)
  }),
  getClassMetadata: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}class-room/${id}/stats`
  }),
  getClassRoom: (id: any,body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/join`
  }),
  getNotes: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}notes/${API_VERSION}/list`,
  }),
  getNotesDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}notes/${API_VERSION}/delete/${id}`
  }),
  getSubjects: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}classroom/${API_VERSION}/classroom-subjects/${id}`
  }),
  getSubjectsDelete: (id: any) => ({
    method: 'delete',
    endpoint: `${HOST}subject/${id}`
  }),
  getCurriculum: (id: string) => ({
    method: 'get',
    endpoint: `${HOST}curriculum/${API_VERSION}/list/${id}`
  }),
  getTodaysEvents: (id: any,body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}event/${id}/list`,body)
  }),
  getEvents: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}events/${API_VERSION}/list`
  }),
  getEventsDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}events/${API_VERSION}/delete/${id}`
  }),
  getDocuments: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}documents/${API_VERSION}/list`,
  }),
  getupdateDocuments: (id: any,body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}documents/${API_VERSION}/update/${id}`
  }),
  getDocumentsDelete: (id: any) => ({
    method: 'delete',
    endpoint:`${HOST}documents/${API_VERSION}/delete/${id}`
  }),
  getActiveClassDetails: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}classroom/${API_VERSION}/details/${id}`
  }),
  getMetaData: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}classroom/${API_VERSION}/${id}/stats`
  }),
  getFeed: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}post/${API_VERSION}/list`
  }),
  getFeedDetails: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}post/${API_VERSION}/details/${id}`
  }),
  getAddNote: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}notes/${API_VERSION}/create`
  }),
  getNoteItem: (id: string) => ({
    method: 'get',
    endpoint: `${HOST}notes/${API_VERSION}/details/${id}`
  }),
  getupdateNote: (id: any,body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}notes/${API_VERSION}/update/${id}`
  }),
  getAddDocument: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}documents/${API_VERSION}/create`
  }),
  getDocumentItem: (id: string) => ({
    method: 'get',
    endpoint: `${HOST}documents/${API_VERSION}/details/${id}`
  }),
  getAddSubject: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}subjects/${API_VERSION}/create`
  }),
  getAddCurriculum: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}curriculum`
  }),
  getupdateCurriculum: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}curriculum/${id}`
  }),
  getCurriculumDelete: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}curriculum/${id}`
  }),
  getupdateSubject: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}subject/${id}`
  }),
  getAddEvents: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}events/${API_VERSION}/create`
  }),
  getupdateEvents: (id: any,body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}events/${API_VERSION}/update/${id}`
  }),
  getS3Delete: (url: string) => ({
    method: 'delete',
    endpoint: `${HOST}api/files?path=${url}`
  }),
  getPostVote: (id: string,type: string) => ({
    method: 'get',
    endpoint: `${HOST}post/${API_VERSION}/vote/${id}/${type}`
  }),
  getComments: (body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}comments/${API_VERSION}/list`
  }),
  getAddComments: (body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}comments/${API_VERSION}/create` 
  }),
  getDeleteComments:(id: any) => ({
    method: 'delete',
    endpoint:`${HOST}comments/${API_VERSION}/delete/${id}`
  }),
  getupdateComments: (id: any,body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}comments/${API_VERSION}/update/${id}`
  }),

  updateTimetable: (id: any, body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}timetable/${API_VERSION}/update/${id}`
  }),
  createTimetable: ( body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}timetable/${API_VERSION}/create`
  }),
  getDeletePost:(id: any) => ({
    method: 'delete',
    endpoint:`${HOST}post/${API_VERSION}/delete/${id}`
  }),
  deactivateAccount:(body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}user/deactivate`
  }),

  getMyActivities: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}activity/${API_VERSION}/list`
  }),

  reportProblem:(body: any) => ({
    method: 'post',
    body,
    endpoint:`${HOST}issues/${API_VERSION}/create`
  }),


  getEditPost: (id: any,body: any) => ({
    method: 'patch',
    body,
    endpoint: `${HOST}post/${API_VERSION}/update/${id}`
  }),
  getReportReason: () => ({
    method: 'post',
    endpoint: `${HOST}issues/${API_VERSION}/report-types`
  }),
  getReportPost: (id:string,body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}post/${API_VERSION}/report/${id}`
  }),

  getUserList:(id:string) => ({
    method: 'get',
    endpoint:`${HOST}classroom/${API_VERSION}/classroom-users-list/${id}`
  }),
  getForgotPassword: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/${API_VERSION}/forgot-password`
  }),
  getForgotPasswordOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/${API_VERSION}/forgot-password-verify-otp`
  }),
  getEmailRetryOtp: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}auth/retry-verify`,body) 
  }),
  getResetPasswordOtp: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}auth/${API_VERSION}/forgot-password-update`
  }),
  getUserPicUpload: (id:string,name:string,body: any) => ({
    method: 'post',
    body,
    endpoint: `${S3UPLOAD}user/${id}/${name}`
  }),
  getUserUpdate: (method:string,body?: any) => ({
    method: method,
    body,
    endpoint: `${HOST}user/${API_VERSION}/user-details`
  }),

  getNotificationList: (body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}notifications/${API_VERSION}/list`
  }),
  getVerifyClassRoom: (body: any) => ({
    method: 'get',
    endpoint:buildGetEndpoint(`${HOST}class-room/check`,body)
  }),
  getTemplateFeed: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}filter/suggest`,body)
  }),
  getBoardTemplate: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}template/suggest`,body)
  }),
  getClassRoomAdd: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room`
  }),
  getClassRoomPrivacy: (id: any,body: any) => ({
    method: 'put',
    body,
    endpoint: `${HOST}class-room/${id}`
  }),
  getLeaveRoom: (id:string,body: any,isUserLeave: boolean = true) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/remove-student?isUserLeave=${isUserLeave}`
  }),
  getUserPeersData: (id: any) => ({
    method: 'get',
    endpoint: `${HOST}user/${API_VERSION}/shared-peers/${id}`,
  }),
  getAddAdmin: (id:string,deleteId:string,type:string) => ({
    method: 'post',
    endpoint: `${HOST}class-room/${id}/add-admin?userId=${deleteId}${type}`
  }),
  getUserSuggest: (body: any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}user/suggest`,body)
  }),
  getAddStudent: (id:string,body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/add-student`
  }),
  getNotificationRead: () => ({
    method: 'post',
    endpoint: `${HOST}notifications/${API_VERSION}/mark-as-read`
  }),
  getJoinRequests: (id:string) => ({
    method: 'get',
    endpoint: `${HOST}classroom/${API_VERSION}/${id}/pending-requests`
  }),
  getActivateStudent: (id:string,body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/activate-student`
  }),
  getDeclineStudent: (id:string,body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}class-room/${id}/decline-student`
  }),
  getClassroomUserSearch: (body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}user/${API_VERSION}/users-list`
  }),
  getPoll: (id: any,body: any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}post/${API_VERSION}/${id}/poll`
  }),
  getChangePassword: (body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}user/${API_VERSION}/change-password`
  }),
  getUniversityList: (body:any) => ({
    method: 'post',
    body,
    endpoint: `${HOST}organizations/${API_VERSION}/selection-list`
  }),
  getClassList: (body?:any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}classroom/${API_VERSION}/classrooms-list`,body??{})
  }),
  getTimeTable: (id?:any) => ({
    method: 'get',
    endpoint: `${HOST}timetable/${API_VERSION}/details/${id}`
  }),
  getFileUpload: (body?:any) => ({
    method: 'get',
    endpoint: buildGetEndpoint(`${HOST}files/signed-url`,body??{})
  }),

  uploadNoteFiles: (body: any,id:string) => ({
    method: 'post',
    body,
    endpoint: `${HOST}notes/${API_VERSION}/upload-file/${id}`
  }),
  
};
