// Core
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Third-Party Libraries
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import _ from 'lodash';

// Components
import GridContainer from 'components/molecules/GridContainer';
import UserCard from 'components/atoms/UserCard';
import NoteFiles from 'components/atoms/NoteFiles';
import NoNotes from 'components/atoms/NoNotes';
import { CreateButton } from 'components/atoms/CustomButtons';
import CreateNoteModal from 'components/atoms/CreateNoteModal';
import ListActionMenu from 'components/atoms/ListActionMenu';
import { useSnackbar } from 'components/atoms/SnackbarProvider';
import CommonDeleteModal from 'components/atoms/CommonDeleteModal';
import DeleteModal from 'components/atoms/DeleteModal';

// Styles and Constants
import './index.scss'
import { colors } from 'assets/styles/colors';

// Utilities
import { useCheckRole, useFetchClassRoomItems } from 'utils/customHooks';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs"
import { capitalizeEachWord, getObjectById, loadActiveClassroomLocalStorage } from 'utils/commonUtils';
import { DocumentDetailsDeleteModal, DocumentsData, MODAL_STYTLE, restrictModalData, SnackBarMessages, StatusType } from 'utils/constants';
import { setDeleteDocument } from '../../redux/slices/setClassroom/classRoomSlice';

const Documents: React.FC = () => {

    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { classroomData } = useSelector((state: any) => state.classRoom) || {};
    const {isInstructor}= useCheckRole()
    const activeClassroom = loadActiveClassroomLocalStorage()
    const { isLastDocument, isDocumentLoading } = classroomData
    const subjects = classroomData?.subjectList
    const [selected, setSelected] = useState<number>(0)
    const [documentList, setDocumentList] = useState<any>([]);
    const [createType, setCreateType] = useState<any>('');
    const { subject, refId } = useParams()
    const [editItem, setEditItem] = useState<any>(null);
    const [isLastPage, setIsLastPage] = useState<boolean>()
    const [page, setPage] = useState<number>(2)
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
    const { fetchDocuments, fetchMetaData } = useFetchClassRoomItems()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [selectedFilter, setSelectedFilter] = useState<any>(null)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [isRestricted, setRestricted] = useState(false);


    const tempSubject=getObjectById(subjects,selectedFilter?._id)

    const loadMore = async (initialPage?: boolean) => {
        if (!isLastPage || initialPage) {
            let params = {
                classRoomId: activeClassroom?._id,
                page: initialPage ? 1 : page,
                limit: 10,
            };
            let filterparams = {
                subjectId:selectedFilter?._id,
                page: initialPage ? 1 : page,
                limit: 10,
                classRoomId: activeClassroom?._id
            };
            setIsLoading(true)
            const res = refId ?
                await fetchData(restAPIs.getDocumentItem(refId), navigate) :
                await fetchData(restAPIs.getDocuments(selectedFilter?._id?filterparams:params), navigate)
            setIsLoading(false)
            if (res?.statusCode === 200 && res?.data) {
                const temp = res.data?.documents??[]
                if (refId) {
                    setDocumentList([res.data])
                }
                else {
                    setDocumentList(initialPage ? temp : documentList.concat(temp))
                }
                setIsLastPage(res.data.isLastPage)
                setPage(initialPage ? 2 : page + 1)
            }
        }
    }

    useEffect(() => {
        if (subject)
            setSelectedFilter({ _id: subject })
        else
            setSelectedFilter(null)
        if (refId) {
            setDocumentList([])
            loadMore(true)
        }
    }, [subject, refId]);

    const onDelete = async () => {
        setDeleteModal(false)
        setIsDeleteLoading(true)
        const res = await fetchData(restAPIs.getDocumentsDelete(documentList?.[selected]?._id), navigate)
        if (res.statusCode == 200) {
            showSnackbar(SnackBarMessages.DocumentDeletedSuccessfully, StatusType.success)
            let temp = [...documentList]
            temp.splice(selected, 1)
            dispatch(setDeleteDocument(documentList[selected]?._id))
            setDocumentList(temp)
            fetchMetaData()
            setIsDeleteLoading(false)
        }
        else {
            setIsDeleteLoading(false)
            showSnackbar(SnackBarMessages.SomethingWentWrong, StatusType.error)
        }
    }
    const onEdit = () => {
        setEditItem(documentList[selected])
        setCreateType(DocumentsData.Document)
    }

    const onCreateNew = () => {
        if (activeClassroom?.memberPermissions?.academic || isInstructor()) {
            setEditItem(null)
            setCreateType(DocumentsData.Document)
        }
        else setRestricted(true)
    }

    useEffect(() => {
        if (selectedFilter) {
            setPage(1)
            setDocumentList([])
            loadMore(true)
            setIsLastPage(false)
        }
        else if (!refId && !subject) {
            setDocumentList(classroomData.documentList)

        }
    }, [selectedFilter]);

    useEffect(() => {
        setIsLastPage(isLastDocument)
    }, [isLastDocument])

    useEffect(() => {
        if (!subject && !refId) {
            setDocumentList(classroomData.documentList)
            setIsLoading(false)
            setSelectedFilter(null)

            setPage(2)
            setIsLastPage(classroomData.isLastDocument)
        }
    }, [classroomData.documentList]);

    const onBack = () => {
        navigate(-1)
    }

    const onClick = (item: any) => {
        if (item === null) {
            navigate('/documents')
            setIsLastPage(false)
            setSelectedFilter(item)
        }
        else {
            setSelectedFilter(item)
        }
    }

    const onClose = () => {
        setEditItem(null)
        setCreateType('')
    }


    return (
        <GridContainer
            pageTitle={!refId && DocumentsData.Documents}
            page={page}
            data={documentList}
            onBack={refId ? onBack : undefined}
            loading={isLoading}
            loadMore={loadMore}
            right={<NoteFiles notes={documentList[selected]} type={DocumentsData.Documents} setNotes={setDocumentList} selected={selected} notesArray={documentList} />}
            leftGridPartition={1 / 2}>
            {!refId &&
                <div className='create-button-wrapper'>
                     {isInstructor() ?
                    <CreateButton bgColor={'#575757'} onClick={onCreateNew}>
                        {DocumentsData.CreateDocument}
                    </CreateButton>:<div/>}
                    {subjects?.length > 0 &&
                        <FormControl sx={{ minWidth: 120, outline: 'none', fontSize: '13px' }} size="small">
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={selectedFilter?._id ?? ''}
                                displayEmpty
                                className='__select'
                            >
                                <MenuItem value={''} onClick={() => onClick(null)}>{DocumentsData.AllSubjects}</MenuItem>
                                {subjects?.map((item: any) => (
                                    <MenuItem value={item?._id} onClick={() => onClick(item)}>{item.subjectName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                </div>}
            <div className='document_container'>
                {documentList?.map((item: any, index: number) => {
                    const fileCount = item?.files?.length
                    return (
                        <div onClick={() => setSelected(index)} className={`notes-container ${selected == index ? 'selected' : ''}`}>
                            <div className="title-note-container">
                                <span className='note-files-name'>{capitalizeEachWord(item?.title)}</span>
                                {(index === selected && isDeleteLoading) ?
                                    <CircularProgress size={20} sx={{ color: colors?.primary }} /> :
                                    <ListActionMenu item={item} onEdit={onEdit} onDelete={() => setDeleteModal(true)} />}
                            </div>
                            <Typography variant='h6' fontWeight={400} style={{ wordWrap: 'break-word' }} fontSize={'12px'} key={index}>
                                {item.description}
                            </Typography>
                            <div className='notes-sub-container'>
                                {item?.subjectName?.length > 0 &&
                                    <Tooltip title={item?.subjectName}>
                                        <div className='notes-sub-name'>
                                            {item?.subjectName}
                                        </div></Tooltip>}
                                <div className='notes-count-name'>
                                    {fileCount} {fileCount == 1 ? DocumentsData.File : DocumentsData.Files}
                                </div>
                            </div>

                            <div className="notes-user-card-wrapper">
                                <UserCard height={32} width={32} item={item} h1FontSize={'11px'} pFontSize={'10px'} />
                            </div>
                            <div className={`${selected == index ? 'dropdown-menu' : 'hide-dropdown-menu'}`} >
                                <NoteFiles notes={documentList[selected]} type={DocumentsData.Documents} />
                            </div>
                            <div className={'line-separator'} />
                        </div>
                    );
                })}
                <CreateNoteModal
                    open={createType == DocumentsData.Document}
                    createType={createType}
                    subject={tempSubject}
                    loadMore={loadMore}
                    editItem={editItem}
                    modalStyle={MODAL_STYTLE}
                    handleClose={onClose} />
                {isLoading && !isLastPage &&
                    <div className='loader-container'>
                        <CircularProgress size={20} sx={{ color: colors?.primary }} />
                    </div>}
                {documentList?.length == 0 && !isLoading &&
                    <NoNotes type={DocumentsData.Documents} />}
                <CommonDeleteModal
                    open={deleteModal}
                    buttonLabel={DocumentDetailsDeleteModal.YesDelete}
                    description={DocumentDetailsDeleteModal.Desc}
                    title={DocumentDetailsDeleteModal.DeleteTitle}
                    handleClose={() => setDeleteModal(false)}
                    handleSubmit={() => onDelete()}
                />
                <DeleteModal
                    open={isRestricted}
                    onSubmit={() => setRestricted(false)}
                    data={restrictModalData}
                    modalStyle={MODAL_STYTLE}
                    handleClose={() => setRestricted(false)}
                />
            </div>
        </GridContainer>
    )
};

export default Documents;
