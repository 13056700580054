import React, { useEffect, useRef, useState } from 'react';
import { Fade, Grid, Modal, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss'
import { useCheckRole, useClassMetadata, useFetchClassRoomItems, useLogout, useSwichClass, useUpdateUser } from 'utils/customHooks';
import { colors } from 'assets/styles/colors';
import { MdOutlineClose } from "react-icons/md";
import CreateButtonModal from '../../atoms/CreateButtonModal';
import CreateNoteModal from '../../atoms/CreateNoteModal';
import CreateSubjectModal from '../../atoms/CreateSubjectModal';
import CreateEventModal from '../../atoms/CreateEventModal';
import DrawerContainer from '../../atoms/DrawerContainer';
import LogOutModal from '../../atoms/LogOutModal';
import { useMetadataContext } from 'contextApi/MetadataContext';
import { getStatus, loadActiveClassroomLocalStorage, loadUserFromLocalStorage } from 'utils/commonUtils';
import { FaSearch } from 'react-icons/fa';
import { LuPlusCircle } from "react-icons/lu";
import { useSnackbar } from '../../atoms/SnackbarProvider';
import ClassRoomDetailModal from 'components/atoms/ClassRoomDetailModal';
import { IoSettingsOutline } from 'react-icons/io5';
import { PATH_TO_CHECK_SIDE_MENU } from 'utils/constants';

const drawerData = [
    'Home',
    'Notes',
    'Documents',
    'Subjects',
    'Events',
    'Curriculum',
    'Timetable'
]

interface Metadata {
    notes: {
        notesCount: number;
    };
    documents: {
        documentsCount: number;
    };
    subjects: {
        subjectsCount: number;
    };
    events: {
        eventsCount: number;
    };
    curriculum: {
        curriculumCount: number;
    };
}

const avatharStyle = {
    bgcolor: colors.secondary,
    width: '35px',
    height: '35px',
    marginLeft: '15px',
    fontSize: '16px'
}

const LeftWidget = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const getUpdateUser = useUpdateUser()
    const { isInstructor } = useCheckRole()
    const getClassMetadata = useClassMetadata();
    const { fetchNotes, fetchDocuments, fetchSubject, fetchTodaysEvents, fetchClassroom, fetchEvents, fetchCurriculum, fetchMetaData, fetchNotifications } = useFetchClassRoomItems()
    const logout = useLogout()
    const user = loadUserFromLocalStorage();
    const activeClassroom = loadActiveClassroomLocalStorage()
    const [open, setOpen] = useState(false);
    const [modalStyle, setModalStyle] = useState({});
    const [activeItem, setActiveItem] = useState('Home');
    const [createType, setCreateType] = useState<any>('');
    const [classRoomDetail, setdetail] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const settingsOpen = Boolean(anchorEl);
    const [openLogout, setOpenLogout] = useState(false);
    const { metadata, setMetadata, isMetaDataLoading, toogleDrawer, setToogleDrawer } = useMetadataContext();

    const [openModalClass, setOpenModalCLass] = useState(false);
    const [position, setPosition] = useState({ top: 0 });
    const buttonRef = useRef(null);
    const { showSnackbar } = useSnackbar();
    const swichClass = useSwichClass()

    const pathCheck = PATH_TO_CHECK_SIDE_MENU.some((path: string) => location.pathname?.includes(path))


    const handleOpenClass = (event: any) => {
        const button: any = buttonRef.current;
        setPosition({
            top: window.scrollY,
        });
        setOpenModalCLass(true);
    };

    const handleCloseClass = () => setOpenModalCLass(false);

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (activeClassroom) {
            fetchMetaData()
            fetchNotifications()
        }
    }, [localStorage.getItem('activeClassroom')]);

    useEffect(() => {
        fetchClassroom(activeClassroom?._id)
        if (user)
            getUpdateUser()
    }, []);

    useEffect(() => {
        if (location.pathname == '/feed' || location.pathname == '/home') {
            setActiveItem('Home')
        }
        else setActiveItem(formatPathToName(location.pathname))

    }, [location]);


    function formatPathToName(path: string): string {
        if (!path) return '';
        const cleanedPath = path?.startsWith('/') ? path?.slice(1) : path;
        return cleanedPath.charAt(0).toUpperCase() + cleanedPath?.slice(1).toLowerCase();
    }

    const handleOpen = (event: any) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const modalWidth = 300;
        const modalHeight = 200;

        setModalStyle(window.innerWidth <= 900 ? { padding: 0 } : {
            top: rect.top + window.scrollY + (rect.height / 2) - (modalHeight / 2) - 150,
            left: rect.right + window.scrollX + 20,
            transform: 'none',
            padding: 0,
        });
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const getRouteNav = (item: string) => {
        setActiveItem(item)
        switch (item) {
            case drawerData[0]:
                return activeClassroom ? '/feed' : '/home'
            case drawerData[1]:
                return '/notes'
            case drawerData[2]:
                return '/documents'
            case drawerData[3]:
                return '/subjects'
            case drawerData[4]:
                return '/events'
            case drawerData[5]:
                return '/curriculum'
            case drawerData[6]:
                return '/timetable'
            case drawerData[9]:
                return '/settings'
            default: return '/home'
        }
    }

    const getCount = (item: any) => {
        if (isMetaDataLoading && item !== drawerData[0]) {
            return '. . .'
        }
        switch (item) {
            case drawerData[0]:
                return ''
            case drawerData[1]:
                return `(${metadata?.notes?.notesCount ?? '0'})`
            case drawerData[2]:
                return `(${metadata?.documents?.documentsCount ?? '0'})`
            case drawerData[3]:
                return `(${metadata?.subjects?.subjectsCount ?? '0'})`
            case drawerData[4]:
                return `(${metadata?.events?.eventsCount ?? '0'})`
            case drawerData[5]:
                return `(${metadata?.curriculum?.curriculumCount ?? '0'})`
        }
    }

    const fetchdata = (item: string) => {
        switch (item) {
            case drawerData[1]:
                fetchNotes(activeClassroom?._id)
                break;
            case drawerData[2]:
                fetchDocuments(activeClassroom?._id)
                break;
            case drawerData[3]:
                fetchSubject(activeClassroom?._id)
                break;
            case drawerData[4]:
                fetchTodaysEvents(activeClassroom?._id)
                fetchEvents(activeClassroom?._id)
                break;
        }
    }

    const onClick = (item: string) => {
        fetchdata(item)
        setToogleDrawer(false);
        navigate(getRouteNav(item));
    }

    const IconLabel = ({ item }: { item: string }) => {
        let iconComponent;
        switch (item) {
            case drawerData[0]:
                iconComponent = <i className="fi fi-rr-house-window"></i>;
                break;
            case drawerData[1]:
                iconComponent = <i className="fi fi-rr-memo-pad"></i>;
                break;
            case drawerData[2]:
                iconComponent = <i className="fi fi-rr-duplicate"></i>;
                break;
            case drawerData[3]:
                iconComponent = <i className="fi fi-rr-book-copy"></i>;
                break;
            case drawerData[4]:
                iconComponent = <i className="fi fi-rr-party-horn"></i>;
                break;
            case drawerData[5]:
                iconComponent = <i className="fi fi-rr-book-alt"></i>;
                break;
            case drawerData[6]:
                iconComponent = <i className="fi fi-rr-clock-time-tracking"></i>;
                break;
        }
        return (
            (!activeClassroom || pathCheck) ? <div></div> :
                <div onClick={() => onClick(item)} className={`Left-widget ${activeItem === item ? 'active' : ''}`}>
                    {iconComponent}
                    <span className='Left-widget-item'>{item} </span>
                </div>
        )
    }

    const handleCloseLogOut = () => setOpenLogout(false);

    const handleOpenLogout = () => {
        handleSettingsClose()
        logout()
    }

    const onClose = (value?: any) => {
        setToogleDrawer(value ?? !toogleDrawer)
    }

    const getInitials = (input: any) => {
        const words = input?.split(' ');
        const firstTwoWords = words?.slice(0, 2);
        const firstLetters = firstTwoWords?.map((word: any) => word.charAt(0).toUpperCase());
        return firstLetters?.join('');
    };

    const handleOpenClassClick = (e: React.MouseEvent<HTMLElement>, item: any) => {
        if (toogleDrawer) {
            setToogleDrawer(false)
        }
        // if (getStatus(item, user?.classRooms) === 'Pending') {
        //     showSnackbar('Waiting for Approval', 'warning')
        //     setdetail(item)
        //     getClassMetadata(item?._id)
        // }
        // else {
        if (activeClassroom?._id != item?._id) {
            setOpenModalCLass(false)
            setTimeout(() => {
                swichClass(item?._id, true)
            }, 120);
        }
        else
            showSnackbar('Already in this class room !', 'warning')
        // }
    };

    const handleDetailClose = () => {
        setdetail(null)
    }

    const handleJoinClassroom = () => {
        setOpenModalCLass(false)
        if (toogleDrawer) {
            setToogleDrawer(false)
        }
        setTimeout(() => {
            navigate('/findclassroom')
        }, 100);
    }

    const handleCreateClassroom = () => {
        setOpenModalCLass(false)
        if (toogleDrawer) {
            setToogleDrawer(false)
        }
        setTimeout(() => {
            navigate('/class-name')
        }, 100);
    }

    const handleOpenClassSettings = () => {
        if (location.pathname !== '/classroom-settings') {
            navigate('/classroom-settings');
            setToogleDrawer(false)
        }
    }
    const name = activeClassroom?.name
    const renderContent = () => (
        <div className='__drawer__'>
            <div
                style={{ borderBottom: location.pathname.includes('/classroomfetch') ? '1px solid #fff' : '1px solid #70707024', borderRight: !pathCheck ? '1px solid #70707024' : '' }}
                className='drawer-header' >
                {activeClassroom && !pathCheck &&
                    <>
                        <div className='__letter'>
                            {getInitials(name)}
                        </div>
                        <div className='__wrap' onClick={handleOpenClass}>
                            <Typography
                                className='__wrap_text'
                                align='left'
                                variant='h3'
                                fontSize={'14px'}
                            >
                                {name?.length > 16 ? name?.slice(0, 16) + '...' : name}
                                {/* {activeClassroom?.name } */}
                            </Typography>
                            <span
                                style={{ fontSize: '20px', lineHeight: '1', position: 'relative', top: '2px', cursor: 'pointer !important' }}

                                color={colors.secondary}
                            ><i style={{ cursor: 'pointer' }} className="fi fi-rr-angle-small-down"></i></span>
                        </div>
                        <div className='__settings'>
                        <IoSettingsOutline style={{ cursor: 'pointer', minWidth: '18px', }} fontSize={18} onClick={handleOpenClassSettings} />
                        </div>
                    </>
                }
            </div>


            <Modal
                open={openModalClass}
                onClose={handleCloseClass}
                closeAfterTransition
                BackdropProps={{ invisible: true }}
            >
                <Fade in={openModalClass}>
                    <div
                        className='modal-container-classroom'
                    >
                        <div className='__classroom_drwr_list'>
                            {user?.classRooms?.map((item: any) => (
                                item &&
                                <div key={item?._id} className='__single_class_drwr' onClick={(e) => handleOpenClassClick(e, item)}>
                                    <div className='__letter'>
                                        {getInitials(item?.name)}
                                    </div>
                                    <div>
                                        <h2>{item?.name}</h2>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Fade>
            </Modal>


            {pathCheck && <div style={{ height: '80px' }} />}
            <div className={activeClassroom ? 'sidemenu-content-wrapper' : 'sidemenu-content-wrap'}>
                {drawerData?.map((item, index) => (
                    <IconLabel key={index} item={item} />
                ))}
                {activeClassroom && !pathCheck && isInstructor() &&
                    <CreateButtonModal
                        setCreateType={setCreateType}
                        open={open}
                        handleClose={handleClose}
                        modalStyle={modalStyle}
                        handleOpen={handleOpen} />}

                <CreateNoteModal
                    open={createType == 'Note' || createType == 'Document'}
                    createType={createType}
                    modalStyle={{ padding: '0px' }}
                    handleClose={() => setCreateType('')} />
                <CreateSubjectModal
                    open={createType == 'Subject'}
                    modalStyle={{ padding: '0px' }}
                    createType={createType}
                    handleClose={() => setCreateType('')} />
                <CreateEventModal
                    open={createType == 'Events'}
                    modalStyle={{ padding: '0px' }}
                    createType={createType}
                    handleClose={() => setCreateType('')} />
                <ClassRoomDetailModal item={classRoomDetail} onClose={handleDetailClose} />
            </div>
        </div>
    )

    return (
        <Grid className={`side-menu-wrapper${toogleDrawer ? '' : '-hide'}`}
            xs={12} sm={12} md={2.4} lg={2.4}
            item>
            <DrawerContainer onClose={onClose}>{renderContent()}</DrawerContainer>

            <LogOutModal
                open={openLogout}
                modalStyle={{ padding: '0px' }}
                handleClose={handleCloseLogOut}
                handleOpen={handleOpenLogout}
            />
        </Grid>
    );
};


export default LeftWidget